import { createI18n } from 'vue-i18n';
import { useWebApp } from 'vue-tg';

import { messages } from '~/texts/index';

const { initDataUnsafe } = useWebApp();

export const i18n = createI18n({
  legacy: false,
  locale: initDataUnsafe.user?.language_code,
  fallbackLocale: 'en',
  messages
});