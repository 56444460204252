import { type Engine } from '@tsparticles/engine';
export { default as Background } from './Background.vue';

let engine: Engine;
export const loadEngine = async () => {
  if (engine) {
    return engine;
  }

  const { tsParticles } = await import('@tsparticles/engine');
  const { loadSlim } = await import('@tsparticles/slim');

  tsParticles.init();
  await loadSlim(tsParticles);

  engine = tsParticles;

  return engine;
};
