import { CanvasRenderingTarget2D } from 'fancy-canvas';
import {
  Coordinate,
  IChartApi,
  ISeriesApi,
  ISeriesPrimitive,
  ISeriesPrimitivePaneRenderer,
  ISeriesPrimitivePaneView,
  SeriesAttachedParameter,
  Time,
  UTCTimestamp,
} from 'lightweight-charts';

export interface HorizontalLineOptions {
  width: number;
  color: string;
  style: 'solid' | 'dashed';
}

type LinePosition = {
  x1?: Coordinate;
  x2?: Coordinate;
  y?: Coordinate;
};

export default class HorizontalLine implements ISeriesPrimitive {
  private chart!: IChartApi;
  private series!: ISeriesApi<'Line'>;
  private _paneViews!: PaneView[];

  constructor(
    private timeFrom: UTCTimestamp,
    private timeTo: UTCTimestamp,
    private price: number,
    options: HorizontalLineOptions
  ) {
    this._paneViews = [new PaneView(this, options)];
  }

  getXFromCoodinate() {
    const timeScale = this.chart.timeScale();
    return timeScale.timeToCoordinate(this.timeFrom) ?? undefined;
  }

  getXToCoodinate() {
    const timeScale = this.chart.timeScale();
    return timeScale.timeToCoordinate(this.timeTo) ?? undefined;
  }

  getYCoodinate() {
    return this.series.priceToCoordinate(this.price) ?? undefined;
  }

  attached(param: SeriesAttachedParameter<Time, 'Line'>): void {
    this.chart = param.chart;
    this.series = param.series;
  }

  updateAllViews() {
    this._paneViews.forEach(view => view.update());
  }

  paneViews() {
    return this._paneViews;
  }
}

class PaneView implements ISeriesPrimitivePaneView {
  private position: LinePosition = {};

  constructor(private source: HorizontalLine, private options: HorizontalLineOptions) {  }

  update() {
    this.position = {
      x1: this.source.getXFromCoodinate(),
      x2: this.source.getXToCoodinate(),
      y: this.source.getYCoodinate(),
    };
  }

  renderer() {
    return new PaneViewRenderer(this.position, this.options);
  }
}

class PaneViewRenderer implements ISeriesPrimitivePaneRenderer {
  constructor(private position: LinePosition, private options: HorizontalLineOptions) { }

  draw(target: CanvasRenderingTarget2D) {
    target.useBitmapCoordinateSpace(({ context, horizontalPixelRatio }) => {
      if (!this.position.x1 || !this.position.x2 || !this.position.y) {
        return;
      }

      const dpr = horizontalPixelRatio;
      const x1 = this.position.x1 * dpr;
      const x2 = this.position.x2 * dpr;
      const y = this.position.y * dpr;

      context.save();

      context.setLineDash(this.options.style === 'dashed' ? [5 * dpr, 5 * dpr] : []);
      context.strokeStyle = this.options.color;
      context.fillStyle = this.options.color;
      context.lineWidth = this.options.width * dpr;

      context.moveTo(x1, y);
      context.arc(x1, y, 2 * dpr, 0, Math.PI * 2);
      context.fill();
      context.lineTo(x2, y);
      context.stroke();
      context.arc(x2, y, 2 * dpr, 0, Math.PI * 2);
      context.fill();

      context.restore();
    });
  }
}
