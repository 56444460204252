import { reactive } from 'vue';
import { until } from '@vueuse/core';
import { type TonConnectUI, type ConnectedWallet } from '@tonconnect/ui';
import { type TonClient } from '@ton/ton';

import { tonConnectManifestUrl, twaReturnUrl, toncenterRPCUrl } from '~/configuration';
import { isAuthenticated, isReady as isAuthReady } from './Auth';
import { socket, isReady as isConnectionReady } from './Connection';
import { identity } from './Account';

export type Wallet = {
  tonWallet: ConnectedWallet | null;
  address: string;
  shortAddress?: string;
  balance: number;
  isConnected: boolean;
};

let tonConnectUI: TonConnectUI;
let tonClient: TonClient;

const status = reactive({
  restoring: true,
  connecting: false,
  disconnecting: false,
  get isLoading() {
    return this.restoring || this.connecting || this.disconnecting;
  },
});

const wallet = reactive<Wallet>({
  tonWallet: null,
  balance: 0,
  address: '',
  get shortAddress() {
    return this.address ? `${this.address.slice(0, 3)}...${this.address.slice(-3)}` : undefined;
  },
  get isConnected() {
    return !!this.tonWallet;
  }
});

const isReady = loadTonConnect().then(() => until(() => !status.restoring).toBeTruthy());

socket.on('wallet:updated', async () => {
  console.log('>> wallet:updated');
  await refreshWallet(wallet.tonWallet);
});

async function connectWallet() {
  console.log('>> Wallet::connectWallet');

  const tonConnect = await loadTonConnect();
  tonConnect.setConnectRequestParameters({
    state: 'ready',
    value: { tonProof: String(identity.id) }
  });

  return tonConnect.openModal();
}

async function chargeWallet(address: string, amount: number, comment: string) {
  if (!wallet.tonWallet) {
    throw new Error('Wallet is not connected');
  }

  console.log('>> Wallet::chargeWallet', address, amount, comment);
  const { beginCell, toNano, Address } = await import('@ton/ton');
  const { CHAIN } = await import('@tonconnect/ui');

  const toTransactionPayload = (comment: string) =>
    beginCell()
      .storeUint(0, 32)
      .storeStringTail(comment)
      .endCell()
      .toBoc()
      .toString('base64');

  const addressObj = Address.parse(wallet.tonWallet.account.address);
  const tonConnect = await loadTonConnect();
  const tonClient = await loadTonClient();
  const beforeState = await tonClient.getContractState(addressObj);
  let timeout = 60;

  await tonConnect.sendTransaction({
    validUntil: Math.round(Date.now() / 1000) + timeout, // timeout is SECONDS
    network: CHAIN.MAINNET,
    messages: [
      {
        amount: toNano(amount).toString(),
        address: Address.parse(address).toRawString(),
        payload: comment ? toTransactionPayload(comment) : undefined
      }
    ]
  });

  return await new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      const afterState = await tonClient.getContractState(addressObj);
      if (beforeState.lastTransaction?.hash !== afterState.lastTransaction?.hash) {
        clearInterval(interval);
        resolve(true);
      }

      timeout -= 1;
      if (!timeout) {
        clearInterval(interval);
        reject(new Error('Transaction approve timeout'));
      }
    }, 1000);
  });
}

async function disconnectWallet() {
  console.log('>> Wallet::disconnectWallet');
  status.disconnecting = true;

  const tonConnect = await loadTonConnect();
  await tonConnect.disconnect();

  wallet.tonWallet = null;
  socket.emit('disconnectWallet');
  status.disconnecting = false;
}

async function refreshWallet(tonWallet: ConnectedWallet | null) {
  if (tonWallet) {
    const { fromNano, Address } = await import('@ton/ton');
    const addressObj = Address.parse(tonWallet.account.address);
    const client = await loadTonClient();
    const balance = await client.getBalance(addressObj);

    wallet.address = addressObj.toString({ bounceable: false })
    wallet.balance = +fromNano(balance);
    wallet.tonWallet = tonWallet;
    identity.wallet = wallet.address;
  } else {
    wallet.tonWallet = null;
    wallet.address = '';
    wallet.balance = 0;
    identity.wallet = '';
  }
}

async function loadTonConnect() {
  if (tonConnectUI) {
    return tonConnectUI;
  }

  const { TonConnectUI, THEME } = await import('@tonconnect/ui');
  tonConnectUI = new TonConnectUI({
    manifestUrl: tonConnectManifestUrl
  });

  tonConnectUI.uiOptions = {
    uiPreferences: {
      theme: THEME.DARK
    },
    actionsConfiguration: {
      twaReturnUrl,
      skipRedirectToWallet: 'never'
    }
  };

  tonConnectUI.onModalStateChange(state => {
    console.log('>> Account.tonConnect.onModalStateChange', state);
    status.connecting = state.status === 'opened';
  });

  tonConnectUI.connectionRestored.then(() => {
    console.log('>> Account.tonConnect.onRestored');
    status.restoring = false;
  });

  tonConnectUI.onStatusChange(async connectedWallet => {
    console.log('>> TonConnectUI onStatusChange', wallet, connectedWallet);
    if (wallet.isConnected && connectedWallet) {
      return; // can't suddenly get another wallet here
    }

    if (connectedWallet) {
      await validateConnectedWallet(connectedWallet);
    }

    status.restoring = false;
  }, (error) => {
    console.log('>> TonConnectUI onStatusChange error', error.message);
  });

  return tonConnectUI;
}

async function loadTonClient() {
  if (tonClient) {
    return tonClient;
  }

  const { TonClient } = await import('@ton/ton');
  tonClient = new TonClient({ endpoint: toncenterRPCUrl });

  return tonClient;
}

async function validateConnectedWallet(connectedWallet: ConnectedWallet) {
  await isAuthReady;
  await isConnectionReady;

  console.log('>> validateConnectedWallet', connectedWallet);

  if (!isAuthenticated.value) {
    return disconnectWallet();
  }

  const { Address } = await import('@ton/ton');
  const address = Address.parse(connectedWallet.account.address).toString({ bounceable: false });
  if (identity.wallet === address) {
    return refreshWallet(connectedWallet);
  }

  const tonProof = connectedWallet.connectItems?.tonProof;
  const signedTonProof = !tonProof || 'error' in tonProof ? undefined : tonProof.proof;

  if (signedTonProof) {
    const valid = await socket.timeout(5000).emitWithAck('connectWallet', {
      account: connectedWallet.account,
      proof: signedTonProof,
    });

    if (valid) {
      return refreshWallet(connectedWallet);
    }

    console.error('>> validateConnectedWallet: invalid tonProof');
  }

  console.error('>> validateConnectedWallet: empty tonProof');
  const tonConnect = await loadTonConnect();
  await tonConnect.disconnect();

  return false;
}

export {
  isReady,
  status,
  wallet,
  connectWallet,
  disconnectWallet,
  chargeWallet,
};
