import { CanvasRenderingTarget2D } from 'fancy-canvas';
import {
  IChartApi,
  ISeriesPrimitive,
  ISeriesPrimitivePaneRenderer,
  ISeriesPrimitivePaneView,
  SeriesAttachedParameter,
  Time,
  UTCTimestamp,
} from 'lightweight-charts';

type ArrowPostion = { x: number; direction: boolean };
export interface ArrowDiretionOptions {
  size: number;
  margin: number;
}

export default class ArrowDirection implements ISeriesPrimitive {
  private chart!: IChartApi;
  private _paneViews!: PaneView[];

  constructor(public getTime: () => number, public direction: boolean, public options: ArrowDiretionOptions) {
    this._paneViews = [new PaneView(this, this.options)];
  }

  getXCoodinate() {
    const timeScale = this.chart.timeScale();
    return timeScale.timeToCoordinate(this.getTime() as UTCTimestamp);
  }

  attached(param: SeriesAttachedParameter<Time, 'Line'>): void {
    this.chart = param.chart;
  }

  updateAllViews() {
    this._paneViews.forEach(view => view.update());
  }

  paneViews() {
    return this._paneViews;
  }
}

class PaneView implements ISeriesPrimitivePaneView {
  private position: ArrowPostion | null = null;

  constructor(private source: ArrowDirection, private options: ArrowDiretionOptions) {  }

  update() {
    const x = this.source.getXCoodinate();
    if (x === null) {
      return;
    }

    this.position = { x, direction: this.source.direction };
  }

  renderer() {
    return new PaneViewRenderer(this.position, this.options);
  }
}

class PaneViewRenderer implements ISeriesPrimitivePaneRenderer {
  constructor(private pos: ArrowPostion | null, private options: ArrowDiretionOptions) { }

  draw(target: CanvasRenderingTarget2D) {
    target.useBitmapCoordinateSpace(({ context, bitmapSize, horizontalPixelRatio }) => {
      if (this.pos === null) {
        return;
      }

      const dpr = horizontalPixelRatio;
      const margin = Math.round(this.options.margin * dpr);
      const direction = this.pos.direction;
      const x = this.pos.x * dpr;
      const headY = direction ? margin : bitmapSize.height - margin;
      const width = Math.round(this.options.size * dpr);
      const shiftY = Math.round(direction ? width / 1.5 : -width / 1.5);
      const color1 = direction ? '#0CE914' : '#EC1212';
      const color2 = direction ? '#07830C' : '#540606';

      const gradient = context.createLinearGradient(x, headY, x, headY + shiftY);
      gradient.addColorStop(0, color1);  
      gradient.addColorStop(1, color2);

      context.save();

      context.fillStyle = gradient;
      context.beginPath();
      roundedPolygon(context, [
        { x: x, y: headY },
        { x: x - (width / 2), y: headY + shiftY },
        { x: x + (width / 2), y: headY + shiftY },
      ], 2 * dpr);
      context.fill();

      context.restore();
    });
  }
}

// Thank you stackoverflow 
// https://stackoverflow.com/questions/44855794/html5-canvas-triangle-with-rounded-corners

type ShapePoint = { x: number, y: number, radius?: number };
type Vector = { x: number, y: number, len: number, nx: number, ny: number, ang: number };

/* eslint-disable */
function roundedPolygon(context: CanvasRenderingContext2D, points: ShapePoint[], radiusAll: number) {
  let i, x, y, len, p1, p2, p3, sinA, sinA90, radDirection, drawDirection, angle, halfAngle, cRadius, lenOut, radius;
  let v1: Vector, v2: Vector;
  // convert 2 points into vector form, polar form, and normalised 
  const asVector = (p: ShapePoint, pp: ShapePoint) => {
    const v: Vector = {
      x: pp.x - p.x,
      y: pp.y - p.y,
      len: 0,
      nx: 0,
      ny: 0,
      ang: 0,
    };

    v.len = Math.sqrt(v.x * v.x + v.y * v.y);
    v.nx = v.x / v.len;
    v.ny = v.y / v.len;
    v.ang = Math.atan2(v.ny, v.nx);

    return v;
  };

  radius = radiusAll;
  len = points.length;
  p1 = points[len - 1];
  // for each point
  for (i = 0; i < len; i++) {
    p2 = points[(i) % len];
    p3 = points[(i + 1) % len];

    //-----------------------------------------
    // Part 1
    v1 = asVector(p2, p1);
    v2 = asVector(p2, p3);
    sinA = v1.nx * v2.ny - v1.ny * v2.nx;
    sinA90 = v1.nx * v2.nx - v1.ny * -v2.ny;
    angle = Math.asin(sinA < -1 ? -1 : sinA > 1 ? 1 : sinA);
    //-----------------------------------------

    radDirection = 1;
    drawDirection = false;
    if (sinA90 < 0) {
      if (angle < 0) {
        angle = Math.PI + angle;
      } else {
        angle = Math.PI - angle;
        radDirection = -1;
        drawDirection = true;
      }
    } else {
      if (angle > 0) {
        radDirection = -1;
        drawDirection = true;
      }
    }
 
    if (p2.radius !== undefined){
        radius = p2.radius;
    } else {
        radius = radiusAll;
    }

    //-----------------------------------------
    // Part 2
    halfAngle = angle / 2;
    //-----------------------------------------

    //-----------------------------------------
    // Part 3
    lenOut = Math.abs(Math.cos(halfAngle) * radius / Math.sin(halfAngle));
    //-----------------------------------------

    //-----------------------------------------
    // Special part A
    if (lenOut > Math.min(v1.len / 2, v2.len / 2)) {
      lenOut = Math.min(v1.len / 2, v2.len / 2);
      cRadius = Math.abs(lenOut * Math.sin(halfAngle) / Math.cos(halfAngle));
    } else {
      cRadius = radius;
    }

    //-----------------------------------------
    // Part 4
    x = p2.x + v2.nx * lenOut;
    y = p2.y + v2.ny * lenOut;
    //-----------------------------------------
    // Part 5
    x += -v2.ny * cRadius * radDirection;
    y += v2.nx * cRadius * radDirection;
    //-----------------------------------------
    // Part 6
    context.arc(x, y, cRadius, v1.ang + Math.PI / 2 * radDirection, v2.ang - Math.PI / 2 * radDirection, drawDirection);
    //-----------------------------------------
    p1 = p2;
    p2 = p3;
  }

  context.closePath();
}
/* eslint-enable */