import { CanvasRenderingTarget2D } from 'fancy-canvas';
import {
  Coordinate,
  IChartApi,
  ISeriesPrimitive,
  ISeriesPrimitivePaneRenderer,
  ISeriesPrimitivePaneView,
  SeriesAttachedParameter,
  Time,
  UTCTimestamp,
} from 'lightweight-charts';

export interface VerticalLineOptions {
  width: number;
  color: string;
  style: 'solid' | 'dashed';
  image?: string;
}

export default class VerticalLine implements ISeriesPrimitive {
  private chart!: IChartApi;

  private _paneViews!: PaneView[];
  private _image: ImageBitmap | null = null;

  constructor(private getTime: () => number, options: VerticalLineOptions) {
    this._paneViews = [new PaneView(this, options, () => this._image)];

    if (options.image) {
      this.loadImage(options.image);
    }
  }

  async loadImage(imgBase64: string) {
    this._image = await fetch(imgBase64)
      .then(res => res.blob())
      .then(blob => createImageBitmap(blob));
  }

  getXCoodinate() {
    const timeScale = this.chart.timeScale();
    return timeScale.timeToCoordinate(this.getTime() as UTCTimestamp);
  }

  attached(param: SeriesAttachedParameter<Time, 'Line'>): void {
    this.chart = param.chart;
  }

  updateAllViews() {
    this._paneViews.forEach(view => view.update());
  }

  paneViews() {
    return this._paneViews;
  }
}

class PaneView implements ISeriesPrimitivePaneView {
  private x: Coordinate | null = null;

  constructor(
    private source: VerticalLine,
    private options: VerticalLineOptions,
    private getImage: () => ImageBitmap | null
  ) {  }

  update() {
    this.x = this.source.getXCoodinate();
  }

  renderer() {
    return new PaneViewRenderer(this.x, this.options, this.getImage);
  }
}

class PaneViewRenderer implements ISeriesPrimitivePaneRenderer {
  constructor(
    private x: Coordinate | null,
    private options: VerticalLineOptions,
    private getImage: () => ImageBitmap | null
  ) { }

  draw(target: CanvasRenderingTarget2D) {
    target.useBitmapCoordinateSpace(({ context, bitmapSize, horizontalPixelRatio }) => {
      if (this.x === null) {
        return;
      }

      const dpr = horizontalPixelRatio;
      const image = this.getImage();
      const imageSize = 30 * dpr;
      const bitX = Math.round(this.x) * dpr;

      context.save();

      context.setLineDash(this.options.style === 'dashed' ? [5 * dpr, 5 * dpr] : []);
      context.strokeStyle = this.options.color;
      context.lineWidth = this.options.width * dpr;

      context.moveTo(bitX, 0);
      context.lineTo(bitX, bitmapSize.height - (image ? imageSize : 0));
      context.stroke();

      if (image) {
        context.drawImage(image, bitX - imageSize / 2, bitmapSize.height - imageSize, imageSize, imageSize);
      }

      context.restore();
    });
  }
}
