<script lang="ts" setup>
import { RouterLink } from 'vue-router';
import Button from '~/components/common/Button.vue';
import Labelled from '~/components/common/Labelled.vue';
import StarfallIcon from '~/assets/icons/starfall.svg';
import LeaderboardIcon from '~/assets/icons/leaderboard.svg';
import ReferralsIcon from '~/assets/icons/referrals.svg';

const nav = [
  { name: 'market', icon: StarfallIcon, label: 'Market' },
  { name: 'tasks', icon: LeaderboardIcon, label: 'Tasks' },
  { name: 'mining', icon: LeaderboardIcon, label: 'Mining' },
  { name: 'team', icon: ReferralsIcon, label: 'Team' },
];
</script>

<template>
  <div class="nav-menu">
    <div class="nav-pill">
      <RouterLink
        v-for="link in nav"
        :key="link.name"
        custom
        :to="link.name"
        v-slot="{ isActive, navigate }"
      >
        <Button :class="isActive ? 'selected' : ''" @click="() => navigate()">
          <Labelled :below="link.label" :size="0.7">
            <component :is="link.icon" class="icon" />
          </Labelled>
        </Button>
      </RouterLink>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.nav-menu {
  z-index: 5;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px 16px;
}

.nav-pill {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  padding: 0 12px;
  border-radius: 35px;
  background: #202228;
  border: 0.5px solid #32343c;
  filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.9));
}

.labelled :deep(.label) {
  text-transform: none;
}

.button {
  padding: 8px;
  & > * {
    transform: scale(1) !important;
  }
  &:active .icon {
    transform: scale(0.85) !important;
  }

  .icon {
    height: 32px;
    transition: transform 0.2s ease;
    :deep(path) {
      transition: fill 1s;
    }
  }
  &:not(.selected) .icon :deep(path) {
    fill: initial;
  }
  &:not(.selected) :deep(.label) {
    color: #b0b0c0;
  }
}
</style>
