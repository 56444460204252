<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { MainButton, useWebApp, useWebAppTheme } from 'vue-tg';
import Button from '~/components/common/Button.vue';
import EclipseSpinner from '~/assets/icons/eclipse-half.svg';

const { button_color } = useWebAppTheme().themeParams.value || {};
const { text, disabled = false, progress = false, color = button_color } = defineProps<{
  text: string;
  disabled: boolean;
  progress: boolean;
  color: string;
}>();
const emit = defineEmits<{ click: [] }>();
const { isPlatformUnknown } = useWebApp();
const visible = ref(false);

onMounted(() => {
  setTimeout(() => {
    visible.value = true;
  }, 200);
});
</script>

<template>
  <template v-if="visible">
    <Button
      v-if="isPlatformUnknown"
      :disabled="disabled"
      class="main-button"
      :style="{ background: color }"
      @click="emit('click')"
    >
      {{ text }}
      <EclipseSpinner v-if="progress" class="spinner" />
    </Button>
    <MainButton
      v-else
      :disabled="disabled"
      :progress="progress"
      :text="$t(text)"
      :color="color"
      @click="emit('click')"
    />
  </template>
</template>

<style lang="postcss" scoped>
.main-button {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px 0;
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;

  &:disabled {
    background: #101114;
  }

  .spinner {
    position: absolute;
    top: 12px;
    right: 20px;
    width: 40px;
    height: 40px;
    fill: #fff;
  }
}
</style>
