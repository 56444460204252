import { ref, watch } from 'vue';
import { useWebApp } from 'vue-tg';
import * as Sentry from '@sentry/vue';
import { URL } from 'url';
import { io } from '../../node_modules/socket.io-client/build/esm-debug/index.js';

import { SERVER_URL, socketOptions } from '~/configuration';
import { shitHappened } from './index';
import { jwt, authenticate } from './Auth';

export type LogicException = {
  name: string;
  message: string;
};

export type ConnectionError = Error & { data?: any };

const { initDataUnsafe } = useWebApp();
const isConnected = ref(false);
const connectionError = ref<ConnectionError>();

let isReadyResolve: (value: boolean) => void;
const isReady = new Promise<boolean>(resolve => {
  isReadyResolve = resolve;
});

const socket = io(SERVER_URL, {
  ...socketOptions,
  auth: cb => cb({
    token: jwt.value,
    ...getSentryHeaders()
  }),
});

watch(jwt, (jwt) => {
  if (jwt) {
    console.log('> socket.connect', performance.now());
    socket.connect();
  } else {
    socket.disconnect();
  }
}, { immediate: true });

socket
  .on('connect', onConnect)
  .on('disconnect', onDisconnect)
  .on('error', onError)
  .on('connect_error', onError)
  .on('exception', onException);

async function onConnect() {
  isConnected.value = true;
  connectionError.value = undefined;

  const url = new URL(window.location).searchParams.get('start');
  const start = url || initDataUnsafe.start_param || '';

  console.log('> onConnect', performance.now());
  await Sentry.startSpan(
    { name: 'Connection getState', op: 'socket.getState' },
    async () => isReadyResolve(await socket.timeout(5000).emitWithAck('getState', { start }))
  );
  console.log('> onConnect isReadyResolve', performance.now());
}

function onDisconnect(reason: string) {
  // TODO: handle disconnect reason
  console.log('>>> onSocketDisconnect', reason);
  isConnected.value = false;
}

async function onError(error: Error) {
  console.log('>>> onSocketError', error.message, error.name);
  if (error.name === 'TimeoutError' || error.message === 'Authentication error') {
    return authenticate();
  }
  if (error.message === 'timeout') {
    return socket.connect();
  }

  // let success;
  // try {
  //   success = await authenticate();
  // } catch (authError) {
  //   shitHappened(authError);
  //   success = false;
  // }

  // if (!success) {
  //   isReadyResolve(false);
  //   status.value = false;
  //   connectionError.value = error;
  // }

  isReadyResolve(false);
  isConnected.value = false;
  connectionError.value = error;
}

function onException(exception: LogicException) {
  console.log('>>> onServerException', exception.message);
  if (exception.name === 'ConnectTimeoutError') {
    // Bold attempt
    socket.disconnect();
    setTimeout(() => socket.connect(), 1000);
    return;
  }

  return shitHappened(Error(exception.message, { cause: exception.name }));
}

function getSentryHeaders() {
  const activeSpan = Sentry.getActiveSpan();
  const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined;
  return {
    trace: rootSpan ? Sentry.spanToTraceHeader(rootSpan) : undefined,
    baggage: rootSpan ? Sentry.spanToBaggageHeader(rootSpan) : undefined
  };
}

export {
  socket,
  isConnected,
  connectionError,
  isReady
};
