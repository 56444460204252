import { reactive, computed } from 'vue';
import { useWebApp } from 'vue-tg';
import * as Sentry from '@sentry/vue';
import * as amplitude from '@amplitude/analytics-browser';

import { twaReturnUrl } from '~/configuration';
import { type InitialState } from './index';
import { socket } from './Connection';

export type AccountInitDTO = Identity;

export type Identity = {
  id: string;
  username: string;
  displayName: string;
  isGuest?: boolean;
  wallet?: string;
  level: number;
  onboarding: Record<string, boolean>;
};

export type Referral = {
  id: string;
  displayName: string;
  pictureUrl: string;
  feeCollected: number;
}

// export type Profile = {
//   address: string;
//   owner: string;
//   name: string;
//   uri: string;
//   attributes: { level: string };
// };

const { user } = useWebApp().initDataUnsafe;
const stubIdentity = {
  id: '0',
  level: 1,
  username: user?.username ?? 'guest',
  displayName: user ? user.first_name : 'Guest',
  isGuest: !!user,
  balances: {
    points: 0,
    tickets: {
      bronze: 0,
      silver: 0,
      gold: 0,
    }
  },
  onboarding: {},
};

const identity = reactive<Identity>(stubIdentity);
const referrals = reactive<Referral[]>([]);
const refLink = computed(() => `${twaReturnUrl}?startapp=u${identity.id}`);

socket
  .on('state', onStateInit)
  .on('account:updated', onAccountUpdated)
  .on('referrals', onReferrals);

function onStateInit({ user }: InitialState) {
  console.log('>> User::init', user);
  Object.assign(identity, user);

  Sentry.setUser({
    id: user.id,
    username: user.username,
    isGuest: user.isGuest,
  });
  amplitude.setUserId(user.id);
}

function onAccountUpdated(user: Identity) {
  console.log('>> Account::updated', user);
  Object.assign(identity, user);
}

function onReferrals(refs: Referral[]) {
  console.log('>> Account::referrals', refs);
  referrals.push(...refs.sort((a, b) => b.feeCollected - a.feeCollected));
}

function loadReferrals() {
  console.log('>> loadReferrals');
  socket.emit('loadReferrals');
}

function finishOnboard(tourName: string) {
  socket.emit('finishOnboard', tourName);
}

export {
  identity,
  referrals,
  refLink,
  loadReferrals,
  finishOnboard
};
