import { ISourceOptions } from '@tsparticles/engine';

export type PresetName = 'blues' | 'space' | 'stars';

export const presets: Record<PresetName, ISourceOptions> = {
  blues: {
    detectRetina: true,
    fullScreen: {
      enable: true,
    },
    background: {
      color: '#070717',
    },
    style: {
      filter: 'blur(50px)',
    },
    particles: {
      color: {
        value: ['#091C48', '#1C2951', '#5475FD'],
      },
      number: {
        value: 20,
      },
      shape: {
        type: 'circle',
      },
      opacity: {
        value: 0.3,
      },
      size: {
        value: { min: 200, max: 300 },
      },
      move: {
        enable: true,
        speed: 2,
      },
    },
  },
  space: {
    detectRetina: true,
    fullScreen: {
      enable: true,
    },
    background: {
      color: '#161A4D',
    },
    style: {
      filter: 'blur(100px)',
    },
    particles: {
      color: {
        value: ['#9058C5', '#5C3F9B', '#A174C2'],
      },
      number: {
        value: 30,
      },
      shape: {
        type: 'circle',
      },
      opacity: {
        value: 0.3,
      },
      size: {
        value: { min: 50, max: 100 },
      },
      move: {
        enable: true,
        speed: 1,
      },
    },
  },
  stars: {
    detectRetina: true,
    fullScreen: {
      enable: true,
    },
    style: {
      filter: 'blur(3px)',
    },
    particles: {
      color: {
        value: '#ffffff',
      },
      opacity: {
        value: 0.5,
      },
      number: {
        value: 300,
        density: {
          enable: true,
        },
      },
      links: {
        distance: 80,
        opacity: 0.4,
        enable: true,
      },
      move: {
        enable: true,
        speed: { min: 0.05, max: 0.2 },
      },
      size: {
        value: {
          min: 0.3,
          max: 1.5,
        },
      },
      rotate: {
        value: 45,
      },
      shape: {
        type: 'star',
        options: {
          star: {
            sides: 4,
            inset: 4,
          },
        },
      },
    },
    interactivity: {
      detectsOn: 'window',
      events: {
        onClick: {
          enable: true,
          mode: 'push',
        }
      },
      modes: {
        push: {
          quantity: 1,
        },
      },
    }
  }
};
