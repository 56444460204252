<script setup lang="ts">
import { useWebApp } from 'vue-tg';
import Button from '~/components/common/Button.vue';

const { close } = useWebApp();

const openChannel = () => {
  window.open('https://t.me/vangabit', '_blank');
  close();
};
</script>

<template>
  <div class="closed-beta">
    <div class="hint">
      <h2>Welcome seer!</h2>
      <h3>This game is in closed beta.</h3>
      <p>
        The only way to get access is through sponsored plays which we often publish in
        our channel.
      </p>
      <p>Subscribe and get notified about the next opportunity.</p>
    </div>

    <Button @click="openChannel"> Join Vanga Legion </Button>
  </div>
</template>

<style lang="postcss" scoped>
.closed-beta {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #202228;
}

.hint {
  width: 70%;
  text-align: center;
}

.button {
  margin-top: 20px;
  padding: 16px 32px;
  font-size: 1.2rem;
  background: #2f80ed;
  border-radius: 8px;
}
</style>
