<template>
  <Transition name="scale-out-in" mode="out-in" appear>
    <slot></slot>
  </Transition>
</template>

<style lang="postcss">
.scale-out-in-enter-from,
.scale-out-in-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
.scale-out-in-enter-active,
.scale-out-in-leave-active {
  transition: all 0.2s ease;
}
</style>
