<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  duration?: number;
  scale?: number;
}>();

const duration = computed(() => (props.duration ? props.duration / 1000 : 0.3) + 's');
const scaleFrom = computed(() => `scale(${props.scale || 1.4})`);
const scaleTo = computed(() => `scale(${1 / (props.scale || 1.4)})`);
</script>

<template>
  <Transition name="fade" appear>
    <slot></slot>
  </Transition>
</template>

<style lang="postcss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all v-bind(duration);
}
.fade-enter-from {
  opacity: 0;
  transform: v-bind(scaleFrom) !important;
}
.fade-leave-to {
  opacity: 0;
  transform: v-bind(scaleTo) !important;
}
</style>
