<script setup lang="ts">
import { ref } from 'vue';
import DurationStep from '~/components/forge/DurationStep.vue';
import QuorumStep from '~/components/forge/QuorumStep.vue';
import RiskStep from '~/components/forge/RiskStep.vue';
import StartAtStep from '~/components/forge/StartAtStep.vue';
import BankStep from '~/components/forge/BankStep.vue';

const steps = [
  { label: 'Duration', component: DurationStep },
  { label: 'Quorum', component: QuorumStep },
  { label: 'Risk', component: RiskStep },
  { label: 'Start', component: StartAtStep },
  { label: 'Fund it', component: BankStep },
];
const selected = ref(0);
</script>

<template>
  <div class="forge">
    <div class="progress">
      <div
        v-for="(step, i) in steps"
        :key="step.label"
        class="step"
        :class="{ current: i === selected }"
      >
        <div class="line"></div>
        <div class="point">{{ step.label }}</div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.forge {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress {
  z-index: 2;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: #202228;
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.6);
  border-bottom: 0.5px solid #32343c;
}

.step {
  min-width: 30%;
  display: flex;
  padding: 20px 0 12px;
}

.line {
}
</style>
