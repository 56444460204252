<script setup lang="ts">
import { ref, onMounted, toValue } from 'vue';
import { VOnboardingWrapper, VOnboardingStep, useVOnboarding } from '~/components/external/v-onboarding';
import Button from '~/components/common/Button.vue';
import { type Step } from './index';

const defaultOptions = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 30],
        },
      },
    ],
  },
  overlay: {
    padding: 12,
    borderRadius: 16,
  },
};

const wrapper = ref(null);
const { start, finish, next } = useVOnboarding(wrapper);
onMounted(() => start());

defineProps<{ steps: Step[] }>();
defineEmits(['finish']);
defineExpose({
  start,
  finish,
  next,
});

const getDescription = (step: Step) => toValue(step.content.description)!;
</script>

<template>
  <VOnboardingWrapper
    ref="wrapper"
    :steps="steps"
    :options="defaultOptions"
    @exit="finish"
  >
    <template #default="{ previous, next, step, exit, index }">
      <VOnboardingStep :key="index">
        <div class="step">
          <h2 v-if="step.content.title" class="title">{{ $t(step.content.title) }}</h2>
          <div v-if="step.content.description" class="body">
            {{ $t(getDescription(step)) }}
          </div>
          <div v-if="step.content.buttons" class="btn-row">
            <Button
              v-for="btn in step.content.buttons"
              :key="btn.label"
              @click="btn.fn({ step, previous, next, exit })"
              :class="['btn', btn.cssClass]"
              :id="btn.id"
            >
              {{ $t(btn.label) }}
            </Button>
          </div>
        </div>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
</template>

<style lang="postcss" scoped>
.step {
  min-width: 250px;
  max-width: 350px;
  padding: 28px 20px;
  border-radius: 20px;
  line-height: 1.2em;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  color: #fff;
  outline: none;
}

.title {
  text-align: center;
  font-size: 1.8rem;
  margin: 0 0 28px;
}
.body {
  text-align: center;
  font-size: 1rem;
}
.btn-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 32px;
}
.btn {
  &:hover {
    color: #b0b0d0;
  }

  &.primary {
    font-size: 1.2rem;
  }
}

.fade-enter-active::before,
.fade-leave-active::before {
  transition: opacity 0.5s ease;
  display: block;
}

.fade-enter-from::before,
.fade-leave-to::before {
  opacity: 1;
}
</style>

<style lang="postcss">
/* [data-popper-arrow]::before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  transform: rotate(45deg);
} */
</style>
