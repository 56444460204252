import {
  ISeriesApi,
  ISeriesPrimitive,
  ITimeScaleApi,
  MismatchDirection,
  SeriesType,
  Time,
  UTCTimestamp,
} from 'lightweight-charts';
import { watchEffect, effectScope } from 'vue';
import { getUnixTime } from 'date-fns';

import VerticalLine, { type VerticalLineOptions } from './primitives/VerticalLine';
import HorizontalLine from './primitives/HorizontalLine';
import ArrowDirection from './primitives/ArrowDirection';
import CrossClose from './primitives/CrossClose';
import ScoreValue from './primitives/ScoreValue';
import { type Room, type Bet } from '~/state/Rooms';

const lineOptions: VerticalLineOptions = {
  width: 0.5,
  color: '#A0A0A0',
  style: 'dashed',
};

type Interval = {
  direction: boolean;
  finishLine: VerticalLine;
  arrow: ArrowDirection;
  close: CrossClose;
  fakeDot: CrossClose;
  priceLine?: HorizontalLine;
};

export default class Timeslots<T extends SeriesType> {
  private startTime = 0 as UTCTimestamp;
  private intervals: Interval[] = [];
  private attached: ISeriesPrimitive[] = [];

  private effects = effectScope();

  constructor(private series: ISeriesApi<T>, private timeScale: ITimeScaleApi<Time>, private room: Room) {
    this.series.subscribeDataChanged(this.onDataUpdate);

    this.effects.run(() => {
      watchEffect(() => {
        this.intervals = this.getIntervals();
        this.attachPrimitives(this.intervals);
      });

      watchEffect(() => {
        if (room.isBetChecking) {
          this.activate(room.lastBet!);
        }
      });

      watchEffect(() => {
        if (room.isBetChecking && this.lastBetPrices.length) {
          this.setPrices(this.lastBetPrices);
        }
      });
    });
  }

  destroy() {
    this.effects.stop();
    this.attached.forEach(primitive => this.series.detachPrimitive(primitive));
    this.series.unsubscribeDataChanged(this.onDataUpdate);
  }

  get directions() {
    return this.room.isBetChecking ? this.room.lastBet!.steps.map(s => s.direction) : this.room.steps;
  }

  get lastBetPrices() {
    return this.room.lastBet
      ? this.room.lastBet.steps.filter(s => s.startPrice).map(s => s.startPrice!)
      : [];
  }

  get winScore() {
    return this.intervals.length ? this.room.rules.winScores[this.intervals.length - 1] : 0;
  }

  get winScoreTime() {
    return this.startTime + this.room.rules.stepDurarion * this.intervals.length + 5 as UTCTimestamp;
  }

  getIntervals(): Interval[] {
    const duration = this.room.rules.stepDurarion;

    return this.directions.map((direction, i) => {
      const finishLine = new VerticalLine(() => this.startTime + duration * (i + 1), lineOptions);
      const arrow = new ArrowDirection(
        () => Math.round(this.startTime + duration * (i + 0.5)),
        direction,
        { size: 25, margin: 15 }
      );
      const close = new CrossClose(
        () => Math.round(this.startTime + duration * (i + 0.5)),
        () => this.room.removeStep(i),
        { size: 13 }
      );
      const fakeDot = new CrossClose(() => this.startTime, () => {}, { size: 0 });

      return {
        direction,
        finishLine,
        arrow,
        close,
        fakeDot
      };
    });
  }

  activate(bet: Bet) {
    this.startTime = getUnixTime(bet.steps[0].startAt!) as UTCTimestamp;
    while (!this.timeScale.timeToCoordinate(this.startTime)) {
      this.startTime++; // Damn hack
    }

    this.intervals = this.getIntervals();
    this.attachPrimitives(this.intervals);

    this.intervals.forEach(interval => {
      this.series.detachPrimitive(interval.close);
    });
  }

  setPrices(prices: number[]) {
    const duration = this.room.rules.stepDurarion;

    prices.forEach((price, i) => {
      const interval = this.intervals[i];
      if (interval.priceLine) {
        return;
      }

      const startTime = this.startTime + duration * i as UTCTimestamp;
      const endTime = this.startTime + duration * (i + 1) as UTCTimestamp;
      const priceLine = new HorizontalLine(startTime, endTime, price, lineOptions);

      this.attached.push(priceLine);
      this.series.attachPrimitive(priceLine);
      interval.priceLine = priceLine;
    });
  }

  private onDataUpdate = () => {
    if (!this.room.isBetChecking) {
      this.startTime = this.getLatestPriceTime();
    }
  }

  private getLatestPriceTime() {
    return (this.series.dataByIndex(0, MismatchDirection.NearestRight)?.time ?? 0) as UTCTimestamp;
  }

  private attachPrimitives(intervals: Interval[]) {
    this.attached.forEach(primitive => this.series.detachPrimitive(primitive));

    this.attached = intervals.flatMap(i => [i.finishLine, i.arrow, i.close, i.fakeDot]);
    if (intervals.length) {
      this.attached.push(new VerticalLine(() => this.startTime, lineOptions));
      this.attached.push(new ScoreValue(() => this.winScoreTime, this.winScore));
    }

    this.attached.forEach(primitive => this.series.attachPrimitive(primitive));
  }
}