<script lang="ts" setup>
import { computed } from 'vue';
const props = defineProps<{
  above?: string;
  below?: string;
  left?: boolean;
  right?: boolean;
  size?: number;
}>();

const fontSize = computed(() => `${props.size || 0.5}rem`);
</script>

<template>
  <div class="labelled" :class="{ left: props.left, right: props.right }">
    <div v-if="above" class="label">{{ $t(above) }}</div>
    <div class="content"><slot></slot></div>
    <div v-if="below" class="label">{{ $t(below) }}</div>

    <!-- <div class="label">
      <slot name="above">
        <span v-if="above">{{ $t(above) }}</span>
      </slot>
    </div>
    <div class="content"><slot></slot></div>
    <div class="label">
      <slot name="below">
        <span v-if="below">{{ $t(below) }}</span>
      </slot>
    </div> -->
  </div>
</template>

<style lang="postcss" scoped>
.labelled {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.4rem;

  &.left {
    align-items: flex-start;
  }
  &.right {
    align-items: flex-end;
  }
}

.label {
  font-size: v-bind(fontSize);
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  white-space: nowrap;
}

.content {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  line-height: 1;
  white-space: nowrap;
}
</style>
