<script lang="ts">
import { defineComponent } from 'vue';
import GameView from '~/components/game/GameView.vue';
import { find } from '~/state/Rooms';

export default defineComponent({
  beforeRouteEnter(to, _, next) {
    const room = find(to.params.roomId as string);
    room && room.hasJoined && !room.isFinished ? next() : next('/market');
  },
  components: {
    GameView,
  },
  props: {
    roomId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return { room: find(props.roomId)! };
  },
});
</script>

<template>
  <GameView :room="room" />
</template>
