<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  to: 'left' | 'right' | 'top' | 'bottom';
  duration?: number;
}>();

const duration = computed(() => (props.duration ? props.duration / 1000 : 0.8) + 's');
</script>

<template>
  <Transition :name="`slidein-${to}`" mode="out-in" appear>
    <slot></slot>
  </Transition>
</template>

<style lang="postcss">
@each $dir in (left, right, top, bottom) {
  .slidein-$(dir)-enter-active,
  .slidein-$(dir)-leave-active {
    transition: transform v-bind(duration) ease;
  }
}

.slidein-left-enter-from,
.slidein-left-leave-to {
  transform: translateX(100%);
}
.slidein-right-enter-from,
.slidein-right-leave-to {
  transform: translateX(-100%);
}
.slidein-top-enter-from,
.slidein-top-leave-to {
  transform: translateY(100%);
}
.slidein-bottom-enter-from,
.slidein-bottom-leave-to {
  transform: translateY(-100%);
}
</style>
