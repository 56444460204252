<script setup lang="ts">
import { getToast } from './index.ts';
import SlideIn from '~/components/transitions/SlideIn.vue';
import Button from '~/components/common/Button.vue';
import CloseIcon from '~/assets/icons/close.svg';

const toast = getToast();
</script>

<template>
  <SlideIn to="bottom">
    <div v-if="toast" class="toast" :class="toast.type">
      <span class="icon"></span>
      <span class="content">{{ $t(toast.message) }}</span>
      <Button v-if="toast.dismissable" @click="toast.hide" class="button">
        <CloseIcon class="icon" />
      </Button>
    </div>
  </SlideIn>
</template>

<style lang="postcss" scoped>
.toast {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px 20px;
  display: flex;
  background: #101114;
  box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.9);

  &.error {
    background: linear-gradient(#9a0000, #d00000);
  }
}

.content {
  flex: 1;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  height: 16px;
}
</style>
