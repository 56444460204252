import { useWebApp } from 'vue-tg';
import { URL } from 'url';

import router from '~/router';
import { isReady as isConnectionReady } from './Connection';
import { find } from './Rooms';

const { initDataUnsafe } = useWebApp();

const isReady = isConnectionReady.then(async () => {
  const room = getStartRoom();
  if (room) {
    return router.replace({
      name: 'rules',
      params: { roomId: room.id }
    });
  }
});

const getStartRoom = () => {
  const url = new URL(window.location).searchParams.get('start');
  const startapp = url || initDataUnsafe.start_param || '';
  const route = startapp[0];
  const id = startapp.slice(1);

  return route === 'g' ? find(id) : undefined;
}

export {
  isReady,
  getStartRoom
};