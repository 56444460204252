// import { UserAuthInfo } from '@web3auth/base';

type AccessTokenResponse = {
  accessToken: string;
}

type ErrorResponse = {
  error: string;
  message: string;
  statusCode: number;
}

export default class GameApi {
  constructor(private uri: string, private token?: string) { }

  private async request<T extends object>(path: string, method?: string, body?: string | Record<string, any>) {
    const response = await fetch(`${this.uri}/${path}`, {
      method: method || 'GET',
      headers: {
        'Content-Type': typeof body === 'string' ? 'application/x-www-form-urlencoded' : 'application/json',
        'Authorization': this.token ? `Bearer ${this.token}` : '',
      },
      body: body ? (typeof body === 'string' ? body : JSON.stringify(body)) : undefined
    });

    if (!response.ok) {
      throw new Error(response.statusText, { cause: response });
    }

    const json = await response.json() as T | ErrorResponse;
    if ('error' in json) {
      throw new Error(json.message, { cause: json });
    } else {
      return json;
    }
  }

  private async auth(method: string, body: string | Record<string, any>) {
    const response = await this.request<AccessTokenResponse>(`auth/${method}`, 'POST', body);
    return response.accessToken;
  }

  async guestAuth() {
    return this.auth('guest', {});
  }

  // async web3Auth(authInfo: UserAuthInfo & { isWalletConnection?: boolean }) {
  //   return this.auth('web3', authInfo);
  // }

  async tgAuth(tgAuthData: string) {
    return this.auth('tg', tgAuthData);
  }

  // async getPrices<T extends object>(from?: number, to?: number) {
  //   let url = 'prices';
  //   if (from) {
  //     url += `?from=${from}`;
  //     if (to) {
  //       url += `&to=${to}`;
  //     }
  //   }

  //   return this.request<T>(url);
  // }
}