<script lang="ts" setup>
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import { demoCompleted } from '~/components/special/onboard/demo';
import SymbolIcon from '~/assets/symbol.svg';

const router = useRouter();
watch(
  demoCompleted,
  (value) => {
    if (value) {
      router.replace({ name: 'market' });
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="welcome">
    <SymbolIcon class="icon" />
  </div>
</template>

<style lang="postcss" scoped>
.welcome {
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    margin-bottom: 32px;
    width: 100px;
    filter: drop-shadow(2px 6px 4px rgba(0, 0, 0, 0.8));
  }
}
</style>
