<script setup lang="ts">
import { computed } from 'vue';
import PanZoom from '~/components/special/PanZoom.vue';

import RoomTile from './RoomTile.vue';

import BeginnerHint from './BeginnerHint.vue';
import ProgressHint from './ProgressHint.vue';
import { Room } from '~/state/Rooms';
import { totalCompleted } from '~/state/Progress';
import { identity } from '~/state/Account';

const props = defineProps<{ rooms: Room[] }>();
const emit = defineEmits<{ select: [string] }>();

const tileWidth = 150;
const tileHeight = 200;
const gap = 24;

const unique = <T>(array: T[]) => [...new Set(array)];

const showBeginnerHint = computed(
  () => identity.level === 2 && totalCompleted.value === 0
);
const showArrowProgress = computed(
  () => identity.level === 3 && totalCompleted.value === 1
);

const deposits = computed(() =>
  unique(props.rooms.map((r) => r.rules.deposit)).sort((a, b) => b - a)
);
const risks = computed(() =>
  unique(props.rooms.map((r) => r.rules.risk)).sort((a, b) => a - b)
);
const rows = computed(() => deposits.value.length);
const cols = computed(() => risks.value.length);

const size = computed(() => ({
  width: (tileWidth + gap) * cols.value - gap,
  height: (tileHeight + gap) * rows.value - gap,
}));

const getX = (room: Room) => risks.value.indexOf(room.rules.risk);
const getY = (room: Room) => deposits.value.indexOf(room.rules.deposit);
const getClasses = (room: Room) => {
  const x = getX(room);
  const y = getY(room);
  return {
    tile: true,
    [`x-${x}`]: true,
    [`y-${y}`]: true,
    'first-row': x === 0,
    'last-row': x === cols.value - 1,
    'first-col': y === 0,
    'last-col': y === rows.value - 1,
  };
};
const getPosition = (room: Room) => {
  const x = getX(room);
  const y = getY(room);
  return {
    left: (tileWidth + gap) * x + 'px',
    top: (tileHeight + gap) * y + 'px',
  };
};
</script>

<template>
  <PanZoom id="roomgrid">
    <div
      class="roomgrid"
      :style="{ width: `${size.width}px`, height: `${size.height}px` }"
    >
      <BeginnerHint v-if="showBeginnerHint" />
      <ProgressHint v-if="showArrowProgress" :size="size" />

      <RoomTile
        v-for="room in rooms"
        :key="room.id"
        :room="room"
        :class="getClasses(room)"
        :style="getPosition(room)"
        @click="emit('select', room.id)"
      />
    </div>
  </PanZoom>
</template>

<style lang="postcss" scoped>
.roomgrid {
  position: relative;
}

.tile {
  z-index: 1;
  position: absolute;
}
</style>
