<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useWebAppTheme } from 'vue-tg';
import Onboard from '~/components/special/onboard/Onboard.vue';
import Disconnected from '~/components/special/Disconnected.vue';
import ClosedBeta from '~/components/special/ClosedBeta.vue';
import { demoCompleted, introSteps } from '~/components/special/onboard/demo';
import { isConnected } from '~/state/Connection';
import { identity } from '~/state/Account';

const router = useRouter();
const showDemo = ref(false);
const { setHeaderColor, setBackgroundColor } = useWebAppTheme();

onMounted(async () => {
  setHeaderColor('#202228');
  setBackgroundColor('#070717');

  await router.isReady();
  if (router.currentRoute.value?.name !== 'demo' && !demoCompleted.value) {
    showDemo.value = true;
  }
});
</script>

<template>
  <main class="app">
    <Suspense>
      <RouterView v-slot="{ Component }">
        <Transition name="fade" mode="out-in" :duration="300">
          <component :is="Component" class="appview" />
        </Transition>
      </RouterView>
    </Suspense>

    <Onboard v-if="showDemo" :steps="introSteps" />
    <Disconnected v-if="!isConnected" />
    <ClosedBeta v-if="identity.level === 0" />
  </main>
</template>

<style lang="postcss" scoped>
.app {
  width: 100%;
  height: 100%;
  background: linear-gradient(#070717 70%, #091c48 110%);

  /* Looks like suspense causes .appview to be out of this scope */
  :deep(.appview) {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  :deep(canvas) {
    user-select: none;
  }
}

.appview::before {
  content: '';
  position: fixed;
  z-index: 100;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #000;
}

.fade-enter-active::before,
.fade-leave-active::before {
  transition: opacity 0.5s ease;
  display: block;
}

.fade-enter-from::before,
.fade-leave-to::before {
  opacity: 1;
}
</style>

<style lang="postcss">
@import-normalize;
@import-sanitize;

@font-face {
  font-family: SFPro;
  src: url('~/assets/font/SF-Pro-Rounded-Medium.otf') format('opentype');
}

@font-face {
  font-family: Virgil;
  src: url('~/assets/font/Virgil.woff2') format('woff2');
}

body {
  position: relative !important;
  overflow: hidden;
  box-sizing: border-box;
  /* overscroll-behavior: none; */
  touch-action: none;
  user-select: none;
  color: white;
  background-color: #070717;
  font-family: SFPro, sans-serif;
  letter-spacing: 0.1em;
}

body.android {
  font-family: Roboto, Ubuntu, sans-serif;
}

body * {
  box-sizing: inherit;
}

#app {
  width: 100%;
  height: 100%;
}

tc-root * {
  font-family: inherit !important;
  letter-spacing: 0.1em;
}

#tc-widget-root [data-tc-wallets-modal-container] {
  background: none;
}
</style>
