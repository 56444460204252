import { reactive, watch } from 'vue';
import { useNow } from '@vueuse/core';

import { socket } from './Connection';

export type Status = {
  online: number;
  balances: Balances;
  mining: Mining;
  shop: Shop;
};

type StatusUpdateDTO = Partial<Status>;

export type Balances = {
  points: number;
  tickets: {
    id: string;
    amount: number;
  }[];
};

export type Mining = {
  current: number;
  max: number;
  claimAt: number;
};

export type Shop = {
  tickets: {
    id: string;
    name: string;
    price: number;
  }[];
};

const now = useNow({ interval: 1000 });
const status = reactive<Status>({
  online: 0,
  balances: {
    points: 55.34,
    tickets: [],
  },
  mining: {
    current: 3.125,
    max: 4,
    claimAt: 0.8,
  },
  shop: {
    tickets: [
      { id: 'basic', name: 'Basic', price: 10 },
      { id: 'worthy', name: 'Worthy', price: 30 },
      { id: 'major', name: 'Major', price: 100 },
    ],
  },
});

watch(now, () => {
  status.mining.current = Math.min(status.mining.current + 1 / 3600, status.mining.max);
});

socket.on('status:update', onStatusUpdate);

function onStatusUpdate(update: StatusUpdateDTO) {
  console.log('>> Status::update', status);
  Object.assign(status, update);
}

export {
  status
};