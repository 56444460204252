import * as Sentry from '@sentry/vue';
import * as amplitude from '@amplitude/analytics-browser';
import { createApp } from 'vue';
import { EventParams } from '@twa-dev/types';
import VueTelegram from 'vue-tg';

// State must go before App
import { initState } from './state';
import { i18n } from './state/I18N';
import { sentryDSN, version } from './configuration';
import App from '~/components/views/App.vue';
import router from './router';

// window.oncontextmenu = () => false;
const app = createApp(App);

if (import.meta.env.PROD) {
  amplitude.init('efb1f0b8d836dc6726cb9684a320cfae', {
    // serverZone: 'EU', account is in US
    appVersion: version,
    defaultTracking: {
      pageViews: {
        trackHistoryChanges: 'pathOnly'
      },
      formInteractions: false,
      fileDownloads: false,
    }
  });

  Sentry.init({
    dsn: sentryDSN,
    release: version,

    app,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });

  Sentry.captureConsoleIntegration({
    levels: ['log', 'warn', 'error'],
  });
}

const { WebApp } = window.Telegram;
if (WebApp.isExpanded) {
  createApplication();
} else {
  WebApp.onEvent('viewportChanged', ready);
}

function ready(event: EventParams['viewportChanged']) {
  if (event.isStateStable) {
    WebApp.offEvent('viewportChanged', ready);
    createApplication();
  }
}

async function createApplication() {
  await initState();

  return app
    .use(router)
    .use(i18n)
    .use(VueTelegram)
    .mount('#app');
}
