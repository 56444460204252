import { CanvasRenderingTarget2D } from 'fancy-canvas';
import {
  IChartApi,
  ISeriesPrimitive,
  ISeriesPrimitivePaneRenderer,
  ISeriesPrimitivePaneView,
  SeriesAttachedParameter,
  Time,
  UTCTimestamp,
  Point,
  MouseEventParams,
  Coordinate,
} from 'lightweight-charts';

export interface CrossCloseOptions {
  size: number;
}

export default class CrossClose implements ISeriesPrimitive {
  private chart!: IChartApi;
  private _paneViews!: PaneView[];

  constructor(public getTime: () => number, private onClick: () => void, public options: CrossCloseOptions) {
    this._paneViews = [new PaneView(this, this.options)];
  }

  getXCoodinate() {
    const timeScale = this.chart.timeScale();
    return timeScale.timeToCoordinate(this.getTime() as UTCTimestamp);
  }

  getYCoodinate() {
    return this.chart.paneSize().height / 2 as Coordinate;
  }

  attached(param: SeriesAttachedParameter<Time, 'Line'>): void {
    this.chart = param.chart;
    this.chart.subscribeClick(this.onChartClick);
  }

  detached(): void {
    this.chart.unsubscribeClick(this.onChartClick);
  }

  updateAllViews() {
    this._paneViews.forEach(view => view.update());
  }

  paneViews() {
    return this._paneViews;
  }

  onChartClick = (params: MouseEventParams<Time>) => {
    const dpr = window.devicePixelRatio || 1;
    const xCoordinate = this.getXCoodinate();
    const yCoordinate = this.getYCoodinate();
    const halfSize = Math.round(this.options.size * dpr / 2);
    const point = params.point;

    if (
      xCoordinate
      && yCoordinate
      && point
      && point.x > xCoordinate - halfSize
      && point.x < xCoordinate + halfSize
      && point.y > yCoordinate - halfSize
      && point.y < yCoordinate + halfSize
    ) {
      this.onClick();
    }
  };
}

class PaneView implements ISeriesPrimitivePaneView {
  private position: Point | null = null;

  constructor(private source: CrossClose, private options: CrossCloseOptions) {  }

  update() {
    const x = this.source.getXCoodinate();
    const y = this.source.getYCoodinate();

    if (x === null || y === null) {
      return;
    }

    this.position = { x, y };
  }

  renderer() {
    return new PaneViewRenderer(this.position, this.options);
  }
}

class PaneViewRenderer implements ISeriesPrimitivePaneRenderer {
  constructor(private pos: Point | null, private options: CrossCloseOptions) { }

  draw(target: CanvasRenderingTarget2D) {
    target.useBitmapCoordinateSpace(({ context, horizontalPixelRatio }) => {
      if (this.pos === null) {
        return;
      }

      const dpr = horizontalPixelRatio;
      const x = this.pos.x * dpr;
      const y = this.pos.y * dpr;
      const width = Math.round(this.options.size * dpr);

      context.save();

      context.strokeStyle = '#DEDEC2';
      context.lineJoin = 'round';
      context.lineCap = 'round';
      context.lineWidth = Math.round(width / 3);

      context.beginPath();
      context.moveTo(x - width / 2, y - width / 2);
      context.lineTo(x + width / 2, y + width / 2);
      context.moveTo(x - width / 2, y + width / 2);
      context.lineTo(x + width / 2, y - width / 2);
      context.stroke();

      context.restore();
    });
  }
}
