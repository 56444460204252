<script lang="ts" setup>
import { ref, computed } from 'vue';
import Fade from '~/components/transitions/Fade.vue';
import Button from '~/components/common/Button.vue';
import Money from '~/components/common/Money.vue';
// import Lottie from '~/components/special/Lottie.vue';
import UserlinkIcon from '~/assets/icons/userlink.svg';
import CopyIcon from '~/assets/icons/copy.svg';
import { refLink, referrals, loadReferrals } from '~/state/Account';

loadReferrals();

const showPill = ref(false);
const displayLink = computed(() => refLink.value.replace('https://', ''));
const copyLink = () => {
  navigator.clipboard.writeText(refLink.value);
  showPill.value = true;
  setTimeout(() => (showPill.value = false), 2000);
};
</script>

<template>
  <div class="referrals">
    <div class="hint">
      <UserlinkIcon class="icon" />
      {{ $t('Your referral link') }}
    </div>
    <div class="link">
      <span class="reflink">{{ displayLink }}</span>
      <Button @click="copyLink">
        <CopyIcon class="icon" />
      </Button>
    </div>

    <Fade>
      <div v-if="showPill" class="pill">{{ $t('Copied!') }}</div>
    </Fade>

    <div class="list-container">
      <h2 class="title">{{ $t('Your squad') }}</h2>
      <ul v-if="referrals.length > 0" class="list">
        <li v-for="referral in referrals" :key="referral.id">
          <!-- <div class="picture">
            <img v-if="referral.pictureUrl" :src="referral.pictureUrl" />
          </div> -->
          <div class="name">{{ referral.displayName }}</div>
          <Money :value="referral.feeCollected" :precision="4" class="fees" />
        </li>
      </ul>
      <div v-else class="lottie-container">
        <!-- <Lottie :data="noResultsLottie" class="lottie" /> -->
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.referrals {
  position: relative;
  padding: 12px 20px;
  overflow: auto !important;
}

.hint {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 8px;
  font-size: 1.2rem;
}
.link {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  background: #027dff;
}
.reflink {
  flex: 1;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icon {
  width: 24px;
  height: 24px;
}

.pill {
  position: absolute;
  top: 20%;
  left: 50%;
  padding: 8px 12px;
  border-radius: 8px;
  background: #027dff;
  font-size: 0.8rem;
  transform: translateX(-50%);
}

.list-container {
  padding: 0 12px;
}
.title {
  margin: 20px 0;
  font-size: 1.5rem;
}
.list {
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    gap: 12px;
  }

  > li:nth-child(odd) {
    background: #303240;
  }
}

.picture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fees {
  width: 90px;
  font-size: 0.9rem;
}

.lottie-container {
  display: flex;
  justify-content: center;
}
.lottie {
  width: 60%;
  aspect-ratio: 1;
}
</style>
