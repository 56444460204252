<script setup lang="ts">
import CircleProgress from '~/components/common/CircleProgress.vue';
import TaskRow from './TaskRow.vue';
import { type UserTask } from '~/state/Tasks';

defineProps<{ level: number; tasks: UserTask[] }>();

const toggle = (level: number) => {
  console.log('toggle', level);
};

const countCompleted = (tasks: UserTask[]) => tasks.filter((task) => task.completed).length;
</script>

<template>
  <div class="level-section">
    <div class="header" @click="toggle(level)">
      <h3>Level {{ level }}</h3>
      <CircleProgress
        :progress="countCompleted(tasks) / tasks.length"
        :value="countCompleted(tasks)"
        class="progress"
      />
    </div>
    <div class="content">
      <TaskRow v-for="task in tasks" :key="task.id" :task="task" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  background: #202228;
  border-top: 0.5px solid #32343c;
  border-bottom: 0.5px solid #32343c;
  cursor: pointer;

  h3 {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .progress {
    width: 28px;
    height: 28px;
  }
}

.content {
  overflow: hidden;
}
</style>