<script lang="ts" setup>
import { ref } from 'vue';
const props = defineProps({
  toggle: Boolean,
  disabled: Boolean,
  highlight: Boolean,
  body: Boolean,
});

const emit = defineEmits<{
  click: [pressed: boolean];
}>();

const isPressed = ref(!props.toggle);

function onClick() {
  if (props.toggle) {
    isPressed.value = !isPressed.value;
  }

  emit('click', isPressed.value);
}
</script>

<template>
  <button
    type="button"
    class="button"
    :disabled="disabled"
    :class="{ pressed: toggle && isPressed, highlight, body }"
    @click="onClick"
  >
    <slot></slot>
  </button>
</template>

<style lang="postcss" scoped>
.button {
  position: relative;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  border: 0 none;
  background: none;
  padding: 0;
  cursor: pointer;
  user-select: none;

  white-space: nowrap;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
  line-height: 1em;
  color: white;

  &:disabled {
    cursor: auto;
  }

  &:focus {
    outline: none;
  }

  > * {
    transition: transform 0.1s ease;
  }

  &.pressed,
  &:active:not(:disabled) {
    & > * {
      transform: scale(0.95);
    }

    &.highlight > * {
      filter: drop-shadow(0 0 10px #f9c84b);
    }
  }

  &.body {
    padding: 8px 16px;
    border-radius: 100px;
    /* border: 4px solid rgba(255, 255, 255, 0.24); */
    background: linear-gradient(#ffd701, #ff7b01) no-repeat;
    background-origin: padding-box;
  }
}
</style>
