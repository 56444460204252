<script lang="ts" setup>
import { ref, computed, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { BackButton } from 'vue-tg';
import { Toast } from '~/components/special/toast';
import { Background } from '~/components/special/background';
import SlideIn from '~/components/transitions/SlideIn.vue';
import Fade from '~/components/transitions/Fade.vue';
import GameStats from '~/components/game/GameStats.vue';
import Chart from '~/components/game/chart/Chart.vue';
import Controls from '~/components/game/Controls.vue';
import FinishStatus from '~/components/game/FinishStatus.vue';
import Ranking from '~/components/game/Ranking.vue';
import { type Room } from '~/state/Rooms';

const { room } = defineProps<{ room: Room }>();

const router = useRouter();
const isEnded = computed(() => room.isStarted && !room.isChecking && !room.canAddStep && !room.canPredict);
const isControlsVisible = ref(room.isStarted && !isEnded.value);
const hasBack = computed(() => !room.isPrivate || isEnded.value);
const showRanking = ref(false);

watchEffect(() => {
  if (isEnded.value) {
    setTimeout(() => (isControlsVisible.value = false), 500);
  } else {
    setTimeout(() => (isControlsVisible.value = true), 1000);
  }
});

const toMarket = () => router.push({ name: 'market' });
</script>

<template>
  <div class="game-room">
    <BackButton v-if="hasBack" @click="toMarket" />
    <Background preset="space" />

    <GameStats class="game-stats" :room="room" @ranking="showRanking = $event" />

    <div class="workzone">
      <Toast />
      <SlideIn to="bottom">
        <Ranking v-if="room.isFinished || showRanking" :room="room" />
      </SlideIn>

      <Chart :room="room" class="chart" />

      <div class="controls-container">
        <SlideIn to="top">
          <Controls v-if="isControlsVisible" :room="room" />
        </SlideIn>

        <Fade :duration="700">
          <FinishStatus v-if="room.isFinished" :room="room" @back="toMarket" />
        </Fade>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.game-room {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.game-stats {
  flex-shrink: 0;
}

.workzone {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  & > :deep(.ranking) {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 75%;
  }
}

.chart {
  flex: 1;
}

.controls-container {
  height: 25%;
  display: flex;
  justify-content: center;
}
</style>
