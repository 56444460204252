<script setup lang="ts">
import { watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { BackButton } from 'vue-tg';
import Labelled from '~/components/common/Labelled.vue';
import StyleValue from '~/components/common/StyleValue.vue';
import Timer from '~/components/common/Timer.vue';
import Money from '~/components/common/Money.vue';
import RulesList from './RulesList.vue';
import MainButton from './MainButton.vue';
import RiskColorIcon from '~/assets/icons/riskcolor.vue';
import { Room } from '~/state/Rooms';


const { room, direct } = defineProps<{ room: Room; direct: boolean }>();
const router = useRouter();

watchEffect(() => {
  if (room.hasJoined && room.startAt) {
    router.push({ name: 'game', params: { roomId: room.id } });
  }
});

const toMarket = () => router.push({ name: 'market' });
</script>

<template>
  <div class="rules">
    <BackButton v-if="!direct" @click="toMarket" />

    <div v-if="room.isFinished" class="finished">
      <StyleValue color="silver" class="label">Game over</StyleValue>
    </div>

    <template v-else>
      <div class="status">
        <div v-if="room.inProgress" class="timer">
          <Labelled above="Finish in" :size="0.8">
            <StyleValue color="gold">
              <Timer :seconds="room.finishIn" />
            </StyleValue>
          </Labelled>
        </div>
        <div v-else-if="room.startAt" class="timer">
          <Labelled above="Start in" :size="0.8">
            <StyleValue color="silver">
              <Timer :seconds="room.startIn" />
            </StyleValue>
          </Labelled>
        </div>
        <div v-else class="quorum">
          <Labelled above="Quorum" :size="0.8">
            <StyleValue color="silver">
              {{ room.totalPlayers }} / {{ room.rules.minQuorum }}
            </StyleValue>
          </Labelled>
        </div>
      </div>

      <div class="specials">
        <Labelled :below="`Risk ${room.rules.risk * 100}%`" :size="0.8" class="risk">
          <RiskColorIcon :risk="room.rules.risk" class="icon" />
        </Labelled>
        <Labelled v-if="room.rules.deposit" below="Deposit" :size="0.7" class="deposit">
          <Money :value="room.rules.deposit" white />
        </Labelled>
      </div>

      <RulesList v-if="!room.isFinished" :room="room" />

      <div v-if="room.bank > 0" class="reward">
        <Labelled below="Bank" :size="0.8">
          <Money :value="room.bank" :precision="0" animated />
        </Labelled>
      </div>

      <div v-if="!room.startAt" class="alert-hint">
        Join now and we will send you an alert when the game is about to start.
      </div>

      <MainButton :room="room" />
    </template>
  </div>
</template>

<style lang="postcss" scoped>
.rules {
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
}

.finished {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .label {
    font-size: 2.4rem;
  }
}

.bell {
  position: absolute;
  top: 20px;
  right: 20px;
}

.status {
  margin: 20px auto;
  font-size: 3rem;

  :deep(.labelled) {
    row-gap: 0.6rem;
  }
}

.specials {
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.deposit {
  font-size: 2.1rem;
}

.risk {
  .icon {
    width: 80px;
  }
}

.reward {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;

  .labelled {
    row-gap: 0.8rem;
  }
}

.alert-hint {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 48px;
  text-align: center;
  color: #b1c3d5;
}
</style>
