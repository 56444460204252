<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { useLocalStorage, useEventListener } from '@vueuse/core';
import panzoom, { type PanZoom } from 'panzoom';

const { id } = defineProps<{ id: string }>();

const currentPos = useLocalStorage(id, { x: 0, y: 0 });
const el = ref<HTMLElement>();
const pz = ref<PanZoom>();

onMounted(() => {
  const instance = panzoom(el.value!, {
    bounds: true,
    boundsPadding: 1,
    maxZoom: 1,
    minZoom: 1,
    disableKeyboardInteraction: true,
    beforeWheel: () => true,
    onTouch: () => false,
  });

  let firstRun = true;
  let startPos = currentPos.value;

  instance.on('transform', (api: PanZoom) => {
    if (firstRun) {
      firstRun = false;
      api.moveTo(currentPos.value.x, currentPos.value.y);
    } else {
      const { x, y } = api.getTransform();
      currentPos.value = { x: Math.round(x), y: Math.round(y) };
    }
  });

  useEventListener(el, 'wheel', (event: WheelEvent) => {
    instance.moveBy(-event.deltaX, -event.deltaY, false);
  });

  useEventListener(el, 'mousedown', () => {
    startPos = currentPos.value;
  });

  useEventListener(el, 'click', (event: Event) => {
    const hasMoved = currentPos.value.x !== startPos.x || currentPos.value.y !== startPos.y;
    if (hasMoved) { // Some little threshold
      event.stopPropagation();
    }
    startPos = currentPos.value;
  }, { capture: true });

  pz.value = instance;
});

onUnmounted(() => {
  pz.value!.dispose();
});
</script>

<template>
  <div ref="el" class="panzoom">
    <slot></slot>
  </div>
</template>

<style lang="postcss" scoped>
.panzoom {
  position: relative;
  width: fit-content;
  height: fit-content;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20%;
}
</style>
