import { ref, computed, reactive, watch } from 'vue';
import { watchDebounced, useIntervalFn, until } from '@vueuse/core';
import { UTCTimestamp } from 'lightweight-charts';
import { getUnixTime } from 'date-fns';

import { isConnected, socket } from './Connection';

export type PriceUpdate = {
  timestamp: string;
  slot: number;
  price: number;
  priceAt: number;
  stalled?: boolean;
};

export type PriceItem = {
  time: UTCTimestamp;
  value: number;
  stalled?: boolean;
};

const history = reactive(new Map<number, PriceItem>());
const lastItem = ref<PriceItem>();
const isActive = ref(false);
const allPrices = computed(() => 
  Array.from(history.values())
    .sort((a, b) => a.time - b.time)
    .filter((item, i, all) => all[i + 1]?.time !== item.time)
);

watch(isConnected, (connected) => {
  if (connected && isActive.value) {
    isActive.value = false;
    subscribe();
  }
});

const { pause: pauseStall, resume: resumeStall } = useIntervalFn(
  () => isActive.value && pushStalledPrice(),
  1000,
  { immediate: false }
);

watchDebounced(() => lastItem.value?.time, resumeStall, { debounce: 2000 });

socket
  .on('price', onPriceUpdate)
  .on('price:history', onPriceHistoryUpdate);

const toPriceItem = ({ timestamp, price, stalled }: PriceUpdate): PriceItem => ({
  time: getUnixTime(timestamp) as UTCTimestamp,
  value: price,
  stalled
});

function onPriceUpdate(update: PriceUpdate) {
  lastItem.value = toPriceItem(update);
  history.set(update.slot, lastItem.value);
  pauseStall();
}

function onPriceHistoryUpdate(prices: PriceUpdate[]) {
  console.log('>> onPriceHistoryUpdate', prices);
  prices.forEach(price => history.set(price.slot, toPriceItem(price)));
  isActive.value = true;
}

async function subscribe() {
  socket.emit('priceSubscribe');
  return until(() => isActive.value).toBeTruthy();
}

function unsubscribe() {
  socket.emit('priceUnsubscribe');
  isActive.value = false;
  setTimeout(pauseStall, 2500);
}

function pushStalledPrice() {
  const previous = lastItem.value!;
  const stalledItem: PriceItem = {
    time: previous.time + 1 as UTCTimestamp,
    value: previous.value,
    stalled: true
  };

  console.log('>>> Stalled price', stalledItem);
  lastItem.value = stalledItem;
}

export {
  lastItem,
  allPrices,
  subscribe,
  unsubscribe
};
