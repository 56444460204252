<script lang="ts">
import { defineComponent } from 'vue';
import RulesView from '~/components/rules/RulesView.vue';
import { find } from '~/state/Rooms';
import { getStartRoom } from '~/state/Navigation';

export default defineComponent({
  beforeRouteEnter(to, _, next) {
    const room = find(to.params.roomId as string);
    if (!room) {
      next('/');
    } else if (room.hasJoined && room.startSoon) {
      next({ name: 'game', params: { roomId: room.id } });
    } else {
      next();
    }
  },
  components: {
    RulesView,
  },
  props: {
    roomId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {
      room: find(props.roomId)!,
      direct: getStartRoom()?.id === props.roomId,
    };
  },
});
</script>

<template>
  <RulesView :room="room" :direct="direct" />
</template>
