import { computed, reactive } from 'vue';
import router from '~/router';
import { socket } from '~/state/Connection';
import { wallet, connectWallet } from '~/state/Wallet';

export type UserTask = {
  id: string;
  level: number;
  title: string;
  description: string;
  points: number;
  completed: boolean;
};

type TaskAction = (task: UserTask) => any | Promise<any>;

const actions: Record<string, TaskAction> = {
  'complete-demo': () => router.push({ name: 'demo' }),
  'connect-wallet': () => !wallet.isConnected && connectWallet(),
};

const allTasks = reactive<UserTask[]>([
  {
    id: 'complete-demo',
    level: 1,
    title: 'Demo game',
    description: 'Earn 3 points in demo game',
    points: 10,
    completed: true,
  },
  {
    id: 'connect-wallet',
    level: 1,
    title: 'Connect Wallet',
    description: 'Connect your TON wallet',
    points: 10,
    completed: false,
  },
  {
    id: 'telegram-channel',
    level: 2,
    title: 'Telegram channel',
    description: 'Subscribe on Vanga channel',
    points: 20,
    completed: false,
  },
  {
    id: 'ticket-game',
    level: 2,
    title: 'Ticket game',
    description: 'Complete your first game on a market',
    points: 20,
    completed: false,
  },
  {
    id: 'claim-points',
    level: 2,
    title: 'Claim points',
    description: 'Claim points 4 times',
    points: 10,
    completed: false,
  },
  {
    id: 'buy-ticket',
    level: 2,
    title: 'Moreeeee',
    description: 'Buy worthy ticket',
    points: 5,
    completed: false,
  },
  {
    id: 'leave-comment',
    level: 3,
    title: 'Leave comment',
    description: 'Leave a comment in the latest channel post',
    points: 30,
    completed: false,
  },
  {
    id: 'market-game',
    level: 3,
    title: 'Market game',
    description: 'Complete market game with deposit',
    points: 30,
    completed: false,
  },
  {
    id: 'private-game',
    level: 3,
    title: 'Private game',
    description: 'Create a private game',
    points: 50,
    completed: false,
  },
]);

const byLevel = computed(() =>
  allTasks.reduce<Record<number, UserTask[]>>((acc, task) => {
    if (!acc[task.level]) {
      acc[task.level] = [];
    }
    acc[task.level].push(task);
    return acc;
  }, {})
);

async function runTask(taskId: string) {
  console.log('>> Task::run', taskId);

  const task = allTasks.find((task) => task.id === taskId)!;
  if (!task.completed) {
    if (await completeTask(taskId)) {
      return;
    }
  }

  if (actions[taskId]) {
    await actions[taskId](task);
  }
}

async function completeTask(taskId: string) {
  console.log('>> Task::complete', taskId);

  const completed = await socket.timeout(5000).emitWithAck('completeTask', taskId);
  if (completed) {
    allTasks.find((task) => task.id === taskId)!.completed = true;
  }
  return completed;
}

export {
  allTasks,
  byLevel,
  runTask,
  completeTask
};