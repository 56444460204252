<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{ progress: number; value: number }>();

const circle = 2 * Math.PI * 80;
const dashOffset = computed(() => circle - circle * props.progress);
</script>

<template>
  <svg viewBox="0 0 200 200" fill="none" class="circle-progress">
    <circle r="80" cx="100" cy="100" class="circle"></circle>
    <circle
      r="80"
      cx="100"
      cy="100"
      :stroke-dasharray="circle"
      :stroke-dashoffset="dashOffset"
      transform="rotate(-90 100 100)"
      class="progress">
    </circle>

    <text x="100" y="130" text-anchor="middle" fill="currentColor" class="value">
      {{ value }}
    </text>
  </svg>
</template>

<style lang="postcss" scoped>
.circle-progress circle {
  stroke: currentColor;
}
.arc {
  stroke: currentColor;
  stroke-width: 12px;
  stroke-linecap: round;
}

.circle {
  stroke: currentColor;
  stroke-width: 8;
  stroke-linecap: round;
  stroke-dasharray: 10 40;
  stroke-dashoffset: 35;
}

.progress {
  stroke-width: 16;
  stroke-linecap: round;
}

.value {
  font-size: 90px;
  font-variation-settings: 'wght' 900;
}
</style>
