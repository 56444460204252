<script setup lang="ts">
import { toRefs, computed } from 'vue';
import Button from '~/components/common/Button.vue';
import { status } from '~/state/Status';

const { current, max, claimAt } = toRefs(status.mining);
const progressPercent = computed(() => `${current.value / max.value * 100}%`);
const canClaim = computed(() => current.value / max.value >= claimAt.value);

const claim = () => {
  console.log('Claimed', current.value);
};
</script>

<template>
  <div class="point-progress">
    <div class="progress-bar">
      <div class="progress" :style="{ width: progressPercent }"></div>
      <Button :disabled="!canClaim" class="claim" @click="claim">Claim</Button>
    </div>
    <span class="current-value" :style="{ left: progressPercent }">{{ current.toFixed(3) }}</span>
  </div>
</template>

<style lang="postcss" scoped>
.point-progress {
  position: relative;
  width: 100%;
}

.progress-bar {
  position: relative;
  width: 100%;
  height: 42px;
  padding: 8px;
  border-radius: 24px 8px 8px 24px;
  background: #202228;
  box-shadow: inset 0 0 2px 1px rgba(255, 255, 255, 0.3);
  overflow: hidden;

  .progress {
    height: 100%;
    border-radius: 24px 4px 4px 24px;
    mask: linear-gradient(#fff 0 0);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, #F5BF03, #E96400);
    }
  }

  .claim {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(20% + 6px);
    border-left: 0.5px solid rgba(255, 255, 255, 0.3);

    &:not(:disabled):after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 8px 4px #F5BF03;
      animation: glow 2s alternate-reverse infinite;
    }
  }
}

.current-value {
  position: absolute;
  top: 110%;
  font-size: 0.75rem;
  transform: translateX(-55%);
}

@keyframes glow {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
</style>