<script setup lang="ts">
import Button from '~/components/common/Button.vue';
import { status } from '~/state/Status';

const getBalance = (ticketId: string) =>
  status.balances.tickets.find((balance) => balance.id === ticketId)?.amount || 0;
</script>

<template>
  <div class="point-shop">
    <div v-for="ticket in status.shop.tickets" :key="ticket.id" class="cell">
      <Button class="ticket">
        <label class="label">{{ ticket.name }}</label>
        <div class="price">✨ {{ ticket.price }}</div>
        <div class="icon"></div>
      </Button>

      <div class="inventory">
        Own {{ getBalance(ticket.id) }}
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.point-shop {
  display: flex;
  gap: 20px;

  > * {
    flex: 1;
  }
}

.ticket {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  gap: 12px;
  background: #202228;
  border: 0.5px solid #32343c;
  border-radius: 4px;

  &:active {
    background: #101214;
    > * {
      transform: scale(1) !important;
    }
  }
}

.label {
  font-size: 1.2rem;
}

.icon {
  width: 36px;
  aspect-ratio: 1;
  border: 0.5px solid #c0c0c0;
  border-radius: 4px;
  background: #0066cc;
}

.inventory {
  margin-top: 8px;
  font-size: 0.8rem;
  text-align: center;
  color: #c0c0c0;
}
</style>