import { computed } from 'vue';
import { useStorage } from '@vueuse/core';
import { decodeJwt } from 'jose';
import { useWebApp } from 'vue-tg';

import { SERVER_URL } from '~/configuration';
import Api from '~/services/Api';
import { shitHappened } from './index';

export type AuthToken = {
  iat: number;
  sub: number;
  username: string;
  name: string;
  exp?: number;
  guest?: boolean;
};

const { initData } = useWebApp();
const jwt = useStorage('accessToken', '');
const token = computed(() => jwt.value ? decodeJwt<AuthToken>(jwt.value) : undefined);
const isAuthenticated = computed(() => !!token.value);

const api = new Api(SERVER_URL);
const isReady = jwt.value ? Promise.resolve(true) : authenticate();

async function authenticate() {
  if (initData) {
    try {
      jwt.value = await api.tgAuth(initData);
    } catch (error) {
      shitHappened(error);
    }
  } else if (import.meta.env.DEV) {
    jwt.value = await api.guestAuth();
  }

  return !!token.value;
}

function invalidate() {
  jwt.value = '';
}

export {
  jwt,
  token,
  isReady,
  isAuthenticated,
  authenticate,
  invalidate,
};
