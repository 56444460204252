<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue';
import Chart from './Chart';
import { type Room } from '~/state/Rooms';
import { subscribe, unsubscribe } from '~/state/Price';

const { room } = defineProps<{ room: Room }>();

const el = ref();
const chart = ref<Chart>();

onMounted(async () => {
  await subscribe();
  chart.value = new Chart(el.value, room);
});

onUnmounted(() => {
  unsubscribe();
  setTimeout(() => chart.value?.destroy(), 500); // Consider transition
});
</script>

<template>
  <div ref="el" class="chart"></div>
</template>

<style lang="postcss" scoped>
.chart {
  letter-spacing: 0.02em;
}
</style>
