import { ref } from 'vue';
import * as Sentry from '@sentry/vue';

import * as Auth from './Auth';
import * as Connection from './Connection';
import * as Navigation from './Navigation';
import * as Status from './Status';
import * as Account from './Account';
import * as Wallet from './Wallet';
import * as Price from './Price';
import * as Rooms from './Rooms';
import * as Progress from './Progress';

declare global {
  interface Window {
    $state: {
      auth: typeof Auth;
      connection: typeof Connection;
      status: typeof Status;
      account: typeof Account;
      wallet: typeof Wallet;
      price: typeof Price;
      rooms: typeof Rooms;
      progress: typeof Progress;
    };
  }
}

export type InitialState = {
  user: Account.AccountInitDTO;
  rooms: Rooms.RoomCreateDTO[];
  progress: Progress.ProgressRecord[];
};

export const initState = async () => {
  window.$state = {
    auth: Auth,
    connection: Connection,
    status: Status,
    account: Account,
    wallet: Wallet,
    price: Price,
    rooms: Rooms,
    progress: Progress,
  };

  await Sentry.startSpan({ name: 'initState' }, async () => {
    await Auth.isReady;
    await Connection.isReady;
    await Navigation.isReady;
  });

  Sentry.setUser(Account.identity);
  Wallet.isReady.then(() => {
    Sentry.setContext('wallet', { ...Wallet.wallet, tonWallet: null });
  });
};

export const globalError = ref<string>();

export function shitHappened(error: Error | string | unknown) {
  if (import.meta.env.DEV) {
    console.error(error);
  } else {
    Sentry.captureException(error);
  }

  globalError.value =
    (error as any).info
    || (error as Error).message
    || (error as object).toString()
    || String(error);
}
