<script setup lang="ts">
import { computed } from 'vue';
import Labelled from '~/components/common/Labelled.vue';
import Money from '~/components/common/Money.vue';
import Timer from '~/components/common/Timer.vue';
import RiskIcon from '~/assets/icons/risk.svg';
import PlayersIcon from '~/assets/icons/players.svg';
import { Room } from '~/state/Rooms';
import { getByRoom } from '~/state/Progress';

const props = defineProps<{ room: Room }>();
const completed = computed(() => getByRoom(props.room)?.completed || 0);
</script>

<template>
  <div class="roomtile" :class="{ joined: room.hasJoined, completed: completed > 0 }">
    <Timer v-if="!room.isStarted && room.startIn > 0" :seconds="room.startIn" class="timer" />
    <Timer v-else-if="room.isStarted && room.finishIn > 0" :seconds="room.finishIn" class="timer" />

    <Labelled left above="Deposit" :size="0.7" class="deposit">
      <Money v-if="room.rules.deposit" :value="room.rules.deposit" />
      <span v-else>Free</span>
    </Labelled>

    <div class="divider">
      <span v-if="room.totalPlayers" class="players">
        <PlayersIcon class="icon" />
        {{ room.totalPlayers }}
      </span>
    </div>

    <Labelled left above="Risk" :size="0.7" class="risk">
      <RiskIcon class="icon" />
      {{ room.rules.risk * 100 }}%
    </Labelled>
  </div>
</template>

<style lang="postcss" scoped>
.roomtile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
  height: 200px;
  padding: 16px;
  border: 0.5px solid #32343c;
  background-color: #101218;
  border-radius: 4px;
  cursor: pointer;

  transition: background 0.3s;

  &.joined {
    animation: flashing 3s infinite;
  }
  &.completed {
    border-width: 2px;
    background: #202430;
  }
  &:active {
    background: #404250;
  }
}

@keyframes flashing {
  0% {
    background: #101218;
  }
  50% {
    background: #202430;
  }
}

.divider {
  position: relative;
  height: 32px;
  display: flex;
  justify-content: center;
  background-color: inherit;

  &::before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0.5px;
    background: #424450;
  }
}

.deposit {
  margin: 0 12px;
  flex: 1;
  font-size: 1.6rem;
}

.risk {
  margin: 0 12px;
  flex: 1;
  font-size: 1.1rem;

  & :deep(.content) {
    gap: 0.8rem;
  }

  & .icon {
    height: 32px;
  }
}

.players {
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  line-height: 1rem;
  background-color: inherit;
  font-size: 0.9rem;

  & .icon {
    height: 16px;
  }
}

.timer {
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  line-height: 1rem;
}
.timer {
  top: -5%;
  right: -5%;
  background: #fff;
  color: #c62128;
  font-size: 0.8rem;
}
</style>
