export const messages = {
  en: {
    // Server errors
    'Another trade is active': 'Another trade is active',
    'Not enough time': 'Not enough time',
    'Game is not started': 'Game is not started',
    'Game is over': 'Game is over',
    'User did not join': 'User did not join',
    'No trades left': 'No trades left',
    'Room not found': 'Room not found',
    'Room is already started': 'Room is already started',
    'Already joined': 'Already joined',
    'Payment required': 'Payment required',

    // Basic
    Demo: 'Demo',
    Score: 'Score',
    Reward: 'Reward',
    Deposit: 'Deposit',
    Duration: 'Duration',
    Predictions: 'Predictions',
    Risk: 'Risk',
    Refund: 'Refund',
    'Too late': 'Too late',
    Wait: 'Wait',
    Go: 'Go',
    Plan: 'Plan',
    Win: 'Win',
    Loss: 'Loss',
    Join: 'Join',
    Joining: 'Joining',
    'Expecting Quorum': 'Expecting Quorum',
    'Opening the game': 'Opening the game',
    'Connect Wallet': 'Connect Wallet',
    'Not enough balance': 'Not enough balance',
    'Game over': 'Game over',
    'Game started': 'Game started',
    'You won': 'You won',
    'Good luck next time!' : 'Good luck next time!',
    'No competition no reward': 'No competition no reward',
    Connect: 'Connect',
    'Connect to receive': '{0} your wallet {1} to receive reward',
    'Back to': 'Back to {0}',
    Bank: 'Bank',
    Quorum: 'Quorum',
    'Start in': 'Start in',
    'Finish in': 'Finish in',
    'Your referral link': 'Your referral link',
    'Copied!' : 'Copied!',
    'Your squad': 'Your squad',
    'Coming soon': 'Coming soon',
    'Online': 'Online { online }',
    Trades: 'Trades',
    Ranking: 'Ranking',
    Market: 'Market',
    Top: 'Top',
    Referrals: 'Referrals',
    'Connect wallet': 'Connect wallet',
    Disconnect: 'Disconnect',
    'Demo game': 'Demo game',
    Skip: 'Skip',
    Ok: 'Ok',
    'Go back': 'Go back',
    'Total rewards': 'Total rewards',
    'Total refunds': 'Total refunds',
    'Your journey starts here': 'Your journey starts here',

    // Demo
    'Start demo': 'Start demo',
    'Welcome seer': 'Welcome seer 🔮',
    DemoIntro: 'First time here? Lets pass through the short demo to understand how to play this game!',
    DemoStep1: 'Your task is to predict Bitcoin price within next 10 seconds.. or more',
    DemoStep2: 'Tap on Up or Down arrow to set a prediction',
    DemoStep3: 'Now tap on the ball to run it',
    DemoStep4: 'Observe how the future unfolds',
    DemoStep5_1: 'Cool! Your are true seer!',
    DemoStep5_2: 'You lost. Try again!',
    DemoStep6: 'You can perform multi-step predictions too. Tap on Up and/or Down multiple times.',
    DemoStep7: 'Good job! You earn points for every successful prediction.',
    DemoStep8: 'Now your task is to score 3 points. You can make any amount of predictions you want. Go on!',
    DemoStep9: 'Great! This is just a demo game. In a real playthrough you will compete within a limited time with other players. The higher your rank the bigger reward you can get.',
    DemoStep10: 'Now lets try your first real game',
    GameHelpLine1: 'You compete with other players in real time price battle',
    GameHelpLine2: 'Predict Bitcoin future to score points and climb the rankings',
    GameHelpLine3: 'Every prediction can have from 1 to 5 steps. More steps — more points to score',
    GameHelpLine4: 'The higher your rank the more rewards you will get',
  },
  ru: {
    // Server errors
    'Another trade is active': 'Другой трейд активен',
    'Not enough time': 'Недостаточно времени',
    'Game is not started': 'Игра не началась',
    'Game is over': 'Игра окончена',
    'User did not join': 'Игрок не присоединился',
    'No trades left': 'Нет доступных трейдов',
    'Room not found': 'Комната не найдена',
    'Room is already started': 'Игра уже началась',
    'Already joined': 'Уже присоединился',
    'Payment required': 'Требуется оплата',

    // Basic
    Demo: 'Демо',
    Score: 'Очки',
    Reward: 'Награда',
    Deposit: 'Депозит',
    Duration: 'Длительность',
    Predictions: 'Предсказаний',
    Risk: 'Риск',
    Refund: 'Возврат',
    'Too late': 'Слишком поздно',
    Wait: 'Жди',
    Go: 'Старт',
    Plan: 'Выбирай',
    Win: 'Точно',
    Loss: 'Неверно',
    Join: 'Войти',
    Joining: 'Входим',
    'Expecting Quorum': 'Ожидаем кворум',
    'Opening the game': 'Игра запускается',
    'Connect Wallet': 'Подключить кошелек',
    'Not enough balance': 'Недостаточно средств',
    'Game over': 'Конец',
    'Game started': 'Игра началась',
    'You won': 'Ты выиграл',
    'Good luck next time!': 'Удачи в следующий раз!',
    'No competition no reward': 'Без конкуренции нет награды',
    Connect: 'Подключи',
    'Connect to receive': '{0} свой кошелек {1} чтобы получить награду',
    'Back to': 'Назад в {0}',
    Bank: 'Банк',
    Quorum: 'Кворум',
    'Start in': 'Старт через',
    'Finish in': 'Финиш через',
    'Your referral link': 'Ваша реферальная ссылка',
    'Copied!' : 'Скопировано!',
    'Your squad': 'Ваш отряд',
    'Coming soon': 'Скоро',
    'Online': 'Онлайн { online }',
    Trades: 'Трейды',
    Ranking: 'Рейтинг',
    Market: 'Рынок',
    Top: 'Топ',
    Referrals: 'Рефералы',
    'Connect wallet': 'Подключить',
    Disconnect: 'Отключить',
    'Demo game': 'Демо игра',
    Skip: 'Пропустить',
    Ok: 'Ок',
    'Go back': 'Назад',
    'Total rewards': 'Всего наград',
    'Total refunds': 'Всего возвратов',
    'Your journey starts here': 'Твой путь начинается тут',

    // Demo
    'Start demo': 'Начать демо',
    'Welcome seer': 'Ванга! 🔮',
    DemoIntro: 'Первый раз здесь? Давай пройдем короткое демо, чтобы понять, как играть в эту игру!',
    DemoStep1: 'Твоя задача — предсказать цену биткоина в течение следующих 10 секунд.. или больше',
    DemoStep2: 'Нажми на Вверх или Вниз, чтобы установить прогноз',
    DemoStep3: 'Теперь нажми на шар, чтобы запустить его',
    DemoStep4: 'Смотри как разворачивается будущее',
    DemoStep5_1: 'Круто! Ты будущий Ванга!',
    DemoStep5_2: 'Не угадал. Попробуй еще!',
    DemoStep6: 'Ты можешь делать многошаговые предсказания. Нажми на Вверх и/или Вниз несколько раз.',
    DemoStep7: 'Молодец! Ты зарабатываешь очки за каждое успешное предсказание.',
    DemoStep8: 'Теперь твоя задача — набрать 3 очка. Можешь делать любое количество предсказаний. Продолжай!',
    DemoStep9: 'Отлично! Это всего лишь демо игра. В реальной игре ты будешь соревноваться в ограниченное время с другими игроками. Чем выше твой рейтинг, тем больше награду ты можешь получить.',
    DemoStep10: 'Теперь давай попробуем твою первую реальную игру',
    GameHelpLine1: 'Ты соревнуешься с другими игроками на графике цены в реальном времени',
    GameHelpLine2: 'Предсказывай будущее биткоина, чтобы набирать очки и подниматься в рейтинге',
    GameHelpLine3: 'Каждое предсказание может иметь от 1 до 5 шагов. Больше шагов — больше очков',
    GameHelpLine4: 'Чем выше твой рейтинг, тем больше награду ты получишь',
  }
};
