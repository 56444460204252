import {
  CrosshairMode,
  type ChartOptions,
  type LineSeriesPartialOptions,
  type AreaSeriesPartialOptions,
  type DeepPartial,
} from 'lightweight-charts';

export const goldColor = {
  lineColor: 'rgba(249, 200, 75, 0.8)',
  topColor: 'rgba(249, 200, 75, 0.6)',
  bottomColor: 'rgba(249, 200, 75, 0)',
};

export const staleColor = {
  lineColor: 'rgba(160, 160, 160, 1)',
  topColor: 'rgba(160, 160, 160, 0.8)',
  bottomColor: 'rgba(160, 160, 160, 0)',
}

export const CHART_CONFIG: DeepPartial<ChartOptions> ={
  handleScroll: false,
  handleScale: false,
  autoSize: true,
  layout: {
    background: { color: 'transparent' },
    textColor: '#F9C84B',
		fontSize: 14,
    fontFamily: 'SFPro',
  },
  grid: {
    vertLines: { visible: false },
    horzLines: { color: 'rgb(212, 173, 230, 0.2)' },
  },
  timeScale: {
    visible: false,
    // tickMarkFormatter: (time: UTCTimestamp) => [
    //   new Date(time * 1000).getMinutes().toString().padStart(2, '0'),
    //   new Date(time * 1000).getSeconds().toString().padStart(2, '0')
    // ].join(':'),
		// tickMarkFormatter: () => '',
    rightOffset: 0,
    barSpacing: 3,
    timeVisible: true,
    secondsVisible: false,
    borderVisible: false,
    uniformDistribution: true,
  },
  rightPriceScale: {
    // alignLabels: false,
    borderVisible: false,
    entireTextOnly: true,
  },
  crosshair: {
    mode: CrosshairMode.Hidden,
  },
};

export const SERIES_CONFIG: AreaSeriesPartialOptions = {
  ...goldColor,
  lineWidth: 1,
  lineType: 2, // LineType.Curved,
  crosshairMarkerVisible: false,
  priceLineVisible: false,
  lastPriceAnimation: 2,
};

export const FAKELINE_CONFIG: LineSeriesPartialOptions = {
	color: '#D4ADE6',
	// lineVisible: false,
	priceLineVisible: false,
	lastValueVisible: false,
};