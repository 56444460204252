<script setup lang="ts"></script>

<template>
  <div class="step risk">Content</div>
</template>

<style lang="postcss" scoped>
.step {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}
</style>
