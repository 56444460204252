<script setup lang="ts">
import { computed } from 'vue';
import MainButton from '~/components/common/MainButton.vue';
import { Room } from '~/state/Rooms';
import { wallet, connectWallet } from '~/state/Wallet';

const { room } = defineProps<{ room: Room }>();
const rules = room.rules;

const visible = computed(() => !room.isStarted);
const disabled = computed(
  () => (room.rules.deposit ? wallet.isConnected : true) && !room.canJoin
);
const color = computed(() => (disabled.value ? '#101114' : '#2ea6ff'));
const text = computed(() => {
  if (room.isStarted) {
    return 'Too late';
  } else if (room.isJoining) {
    return 'Joining';
  } else if (room.canJoin) {
    return 'Join';
  } else if (room.hasJoined && !room.startAt) {
    return 'Expecting Quorum';
  } else if (room.hasJoined) {
    return 'Opening the game';
  } else if (rules.deposit > 0 && !wallet.isConnected) {
    return 'Connect Wallet';
  } else if (wallet.balance < room.rules.deposit) {
    return 'Not enough balance';
  } else {
    return '[[ Weirdo ]]';
  }
});

const onClick = () =>
  rules.deposit > 0 && !wallet.isConnected ? connectWallet() : room.join();
</script>

<template>
  <MainButton
    v-if="visible"
    :disabled="disabled"
    :progress="room.isJoining"
    :text="$t(text)"
    :color="color"
    @click="onClick"
  />
</template>
