<script setup lang="ts">
import Money from '~/components/common/Money.vue';
import { Room } from '~/state/Rooms';
import DurationIcon from '~/assets/icons/duration.svg';
import TradesIcon from '~/assets/icons/trades.svg';

const { room } = defineProps<{ room: Room }>();
const rules = room.rules;

type ListItem = { icon?: string; label: string; value: string; isMoney?: boolean };
const list: ListItem[] = [
  { icon: DurationIcon, label: 'Duration', value: `${rules.duration} min` },
  { icon: TradesIcon, label: 'Trades', value: rules.totalBets.toString() },
];
</script>

<template>
  <ul class="rules-list">
    <li v-for="item in list" :key="item.label">
      <div class="icon">
        <component v-if="item.icon" :is="item.icon" />
      </div>
      <div class="row">
        <div class="label">{{ $t(item.label) }}</div>
        <div class="value">
          <Money v-if="item.isMoney" :value="+item.value" />
          <div v-else>{{ item.value }}</div>
        </div>
      </div>
    </li>
  </ul>
</template>

<style lang="postcss" scoped>
.rules-list {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #202228;
  border-radius: 16px;

  li:last-child .row {
    border-bottom: 0 none;
    margin-bottom: 1px;
  }
}

.rules-list li {
  display: flex;
  align-items: center;
  padding-left: 16px;

  .icon {
    width: 28px;
    height: 44px;
    padding: 8px 0;
    margin-right: 12px;

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  .row {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 16px 10px 0;
    border-bottom: 0.5px solid #404250;
  }

  .label {
    flex: 1;
  }

  .value {
    display: flex;
    color: #aaa;
    white-space: nowrap;
  }
}
</style>
