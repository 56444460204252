<script setup lang="ts">
import { ref, onMounted } from 'vue';
import SlideIn from '~/components/transitions/SlideIn.vue';
import Button from '~/components/common/Button.vue';
import CloseIcon from '~/assets/icons/circle-close.svg';

defineProps<{ open: boolean }>();
const emit = defineEmits(['close']);

const mounted = ref(false);
onMounted(() => (mounted.value = true));
</script>

<template>
  <Teleport v-if="mounted" to=".app">
    <SlideIn to="top">
      <div v-if="open" class="bottom-sheet">
        <div class="overlay" @click="emit('close')"></div>

        <Button class="close" @click="emit('close')">
          <CloseIcon class="icon" />
        </Button>

        <div class="content">
          <slot></slot>
        </div>
      </div>
    </SlideIn>
  </Teleport>
</template>

<style lang="postcss" scoped>
.bottom-sheet {
  z-index: 100;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 70%;
  min-height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px 24px 0 0;
  background-color: #303238;
}

.overlay {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #404040;
  &:hover {
    color: #606060;
  }

  > .icon {
    width: 24px;
    height: 24px;
  }
}

.content {
  overflow: auto;
  height: 100%;
  padding: 16px 40px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
