import {
  ISeriesApi,
  MismatchDirection,
  SeriesType,
  UTCTimestamp,
} from 'lightweight-charts';
import { getUnixTime } from 'date-fns';

import VerticalLine, { type VerticalLineOptions } from './primitives/VerticalLine';
import { type Room } from '~/state/Rooms';
import startFlag from '~/assets/icons/start.avif';
import finishFlag from '~/assets/icons/finish.avif';


export interface TimeslotsOptions {
  startTime: UTCTimestamp;
  finishTime: UTCTimestamp;
}

const getRangeLineOptions = (start: boolean, flag: string): VerticalLineOptions => ({
  width: 2,
  style: 'solid',
  color: start ? '#0A4FB5' : '#EF8901',
  image: flag
});

export default class StartFinish<T extends SeriesType> {
  private startLine: VerticalLine;
  private finishLine: VerticalLine;

  constructor(private series: ISeriesApi<T>, private room: Room) {
    this.startLine = new VerticalLine(() => this.startTime, getRangeLineOptions(true, startFlag));
    this.finishLine = new VerticalLine(() => this.finishTime, getRangeLineOptions(false, finishFlag));

    const latestTime = this.getLatestPriceTime();
    if (latestTime < this.startTime) {
      this.series.attachPrimitive(this.startLine);
    }
    if (latestTime < this.finishTime) {
      this.series.attachPrimitive(this.finishLine);
    }

    this.series.subscribeDataChanged(this.onDataUpdate);
  }

  get startTime() {
    return getUnixTime(this.room.startAt || 0);
  }

  get finishTime() {
    return getUnixTime(this.room.finishAt || 0);
  }

  destroy() {
    this.series.detachPrimitive(this.startLine);
    this.series.detachPrimitive(this.finishLine);
    this.series.unsubscribeDataChanged(this.onDataUpdate);
  }

  private onDataUpdate = () => {
    const latestTime = this.getLatestPriceTime();

    if (latestTime >= this.startTime) {
      this.series.detachPrimitive(this.startLine);
    }

    if (latestTime >= this.finishTime) {
      this.series.detachPrimitive(this.finishLine);
      this.series.unsubscribeDataChanged(this.onDataUpdate);
    }
  }

  private getLatestPriceTime() {
    return (this.series.dataByIndex(0, MismatchDirection.NearestRight)?.time ?? 0) as UTCTimestamp;
  }
}