<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import { type LottiePlayer } from 'lottie-web';

declare const lottie: LottiePlayer;

const props = defineProps<{
  data: object;
  loop?: boolean;
}>();

const el = ref<HTMLElement>();
watch(() => props.data, loadAnimation);
onMounted(() => loadAnimation());

function loadAnimation() {
  if (el.value) {
    lottie.loadAnimation({
      container: el.value,
      animationData: props.data,
      loop: props.loop,
      autoplay: true,
   });
  }
}
</script>

<template>
  <div ref="el" class="lottie"></div>
</template>

<style lang="postcss" scoped>
.lottie {
  width: 100%;
  height: 100%;
}
</style>
