<script setup lang="ts">
import { computed } from 'vue';
import Money from '~/components/common/Money.vue';
import Button from '~/components/common/Button.vue';
import { type Room } from '~/state/Rooms';
import { wallet, connectWallet } from '~/state/Wallet';

const { room } = defineProps<{ room: Room }>();
const emit = defineEmits(['back']);
const wasPlayed = computed(() => room.players.filter(p => p.share > 0).length > 0);
const reward = computed(() => room.gameStats?.reward || 0);
</script>

<template>
  <div class="finish-status">
    <p>
      <template v-if="reward > 0">
        {{ $t('You won') }} <Money :value="reward" />
      </template>
      <template v-else-if="wasPlayed"> {{ $t('Good luck next time!') }} </template>
      <template v-else> {{ $t('No competition no reward') }} </template>
    </p>

    <p>
      <i18n-t v-if="reward > 0 && !wallet.isConnected" keypath="Connect to receive">
        <Button class="inline-button" @click="connectWallet">
          {{ $t('Connect') }}
        </Button>
        <br />
      </i18n-t>
      <i18n-t v-else keypath="Back to">
        <Button class="inline-button" @click="emit('back')">{{ $t('Market') }}</Button>
      </i18n-t>
    </p>
  </div>
</template>

<style lang="postcss" scoped>
.finish-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  > p {
    margin: 0;
    text-align: center;
    font-size: 1.2rem;
  }

  .money {
    vertical-align: middle;
  }
}

.inline-button {
  color: #fabd48;
  text-decoration: underline dotted;
}
</style>
