import { MaybeRefOrGetter } from 'vue';
import { type VOnboardingWrapperOptions } from '~/components/external/v-onboarding/types/lib';

export type Step = {
  attachTo?: {
    element: string | (() => Element | null),
    classList?: string[]
  };
  content: {
    title?: string;
    description?: MaybeRefOrGetter<string>;
    html?: boolean
    buttons?: StepButton[];
  };
  on?: {
    beforeStep?: (options: HookArgs) => void | Promise<void>
    afterStep?: (options: HookArgs) => void | Promise<void>
  };
  filter?: () => boolean | Promise<boolean>;
  options?: VOnboardingWrapperOptions;
};

type HookArgs = {
  index: number;
  step: Step;
  next: () => void;
}

export type StepButton = {
  label: string;
  id?: string;
  cssClass?: string;
  fn: (args: BtnArgs) => void;
};

type BtnArgs = {
  step: Step;
  previous: () => void;
  next: () => void;
  exit: () => void;
}

type Rect = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type Offsets = {
  popper: Rect;
  reference: Rect;
  placement: string;
};

export const popperCenter = {
  options: {
    popper: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: (offsets: Offsets) => {
              return [0, (offsets.reference.height + offsets.popper.height) * -0.5];
            },
          },
        },
      ],
    },
  },
  // attachTo: {
  //   element: '.app',
  // },
};
