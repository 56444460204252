import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// declare module 'vue-router' {
//   interface RouteMeta {
//     save?: boolean;
//   }
// }

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'index',
    component: () => import('~/components/views/Index.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('~/components/views/Home.vue'),
      },
      {
        path: 'market',
        name: 'market',
        component: () => import('~/components/views/Market.vue'),
      },
      {
        path: 'tasks',
        name: 'tasks',
        component: () => import('~/components/views/Tasks.vue'),
      },
      {
        path: 'mining',
        name: 'mining',
        component: () => import('~/components/views/Mining.vue'),
      },
      {
        path: 'team',
        name: 'team',
        component: () => import('~/components/views/Team.vue'),
      },
    ]
  },
  {
    path: '/rules/:roomId',
    name: 'rules',
    props: true,
    component: () => import('~/components/views/Rules.vue'),
  },
  {
    path: '/game/:roomId',
    name: 'game',
    props: true,
    component: () => import('~/components/views/Game.vue'),
  },
  {
    path: '/demo',
    name: 'demo',
    props: true,
    component: () => import('~/components/views/Demo.vue'),
  },
  {
    path: '/forge',
    name: 'forge',
    component: () => import('~/components/views/Forge.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;