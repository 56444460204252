import { ManagerOptions, SocketOptions } from 'socket.io-client';
import pkg from '../package.json';

export const version = pkg.version;

export const socketOptions: Partial<ManagerOptions & SocketOptions> = {
  autoConnect: false,
  path: '/io',
  transports: ['websocket'],
  reconnectionDelay: 5000,
  useNativeTimers: true,
  // ...customConfig
};

export const SERVER_URL = import.meta.env.PUBLIC_GAME_SERVER;

export const getExplorerURL = (address: string) => `https://tonviewer.com/${address}`;

export const sentryDSN = 'https://55053e036abb1d092c3d221ba95709e6@o4507203239673856.ingest.de.sentry.io/4507203242819664';

export const tonConnectManifestUrl = 'https://game.vangabit.com/tonconnect-manifest.json';
export const twaReturnUrl = 'https://t.me/vangabitbot/game';

export const toncenterRPCUrl = 'https://toncenter.com/api/v2/jsonRPC';