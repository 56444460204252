import { reactive, computed } from 'vue';
import { useNow } from '@vueuse/core';
import { differenceInSeconds } from 'date-fns';

import { InitialState, shitHappened } from './index';
import { socket } from './Connection';
import { wallet, chargeWallet } from './Wallet';

export enum RoomType {
  Common = 'common',
  Sponsored = 'sponsored',
  Rare = 'rare',
  Unique = 'unique',
}

export type RoomCreateDTO = {
  id: string;
  type: RoomType;
  startAt: string;
  finishAt: string | null;
  isPrivate: boolean;
  isDemo?: boolean;
  isCompleted: boolean;
  isPaid: boolean;
  totalPlayers: number;

  bank: number;
  address: string;
  rules: GameRules;
  players: Player[];

  bets?: Bet[];
  stats?: GameStats;
};

type GameRules = {
  duration: number;
  deposit: number;
  totalBets: number;
  minQuorum: number;
  maxQuorum?: number;
  risk: number;
  stepDurarion: number;
  winScores: number[];
};

type RoomUpdateDTO = {
  id: string;
  startAt: string;
  finishAt: string | null;
  isCompleted: boolean;
  isPaid: boolean;
  totalPlayers: number;
  bank: number;
  players: Player[];
  bets: Bet[];
  stats?: GameStats;
  totalReward?: number;
  totalRefund?: number;
};

export type Room = Readonly<RoomCreateDTO> & {
  isStarted: boolean;
  isFinished: boolean;
  inProgress: boolean;
  startSoon: boolean;
  startIn: number;
  finishIn: number;

  canJoin: boolean;
  hasJoined: boolean;
  isJoining: boolean;

  gameStats?: GameStats;
  totalReward?: number;
  totalRefund?: number;
  canAddStep: boolean;
  canPredict: boolean;
  isBetChecking: boolean;
  checkTimer: number;
  lastBet?: Bet;
  isChecking: boolean;
  steps: boolean[];
  bets: Bet[];

  join: () => Promise<void>;
  addStep: (value: boolean) => void;
  removeStep: (index: number) => void;
  predict: () => void;
};

export type Player = {
  id: string;
  rank: number;
  name: string;
  score: number;
  share: number;
};

export type GameStats = Player & {
  betsMade: number;
  betsAvailable: number;
  reward: number;
  refund: number;
};

export type Bet = {
  id: string;
  betAt: string;
  stepDuration: number;
  isWin: boolean | null;
  scored: number;
  steps: BetStep[];
};

type BetStep = {
  isChecked: boolean;
  direction: boolean;
  startAt?: Date;
  checkAt?: Date;
  startPrice?: number;
  endPrice?: number;
  success?: boolean;
};

type BetDTO = {
  roomId: string;
  bet: Bet;
};

const now = useNow({ interval: 500 });
const rooms = reactive<Room[]>([]);
const joinedRooms = computed(() =>
  rooms.filter((r) => r.hasJoined && !r.isPaid)
);
const visibleRooms = computed(() => {
  const active = rooms.filter(r => r.isJoining || r.hasJoined && !r.isFinished && !r.isDemo);
  const available = rooms
    .filter(r => !r.startAt || r.startIn > 0)
    .filter(r => !active.some(a => a.rules.deposit === r.rules.deposit && a.rules.risk === r.rules.risk));

  return active.concat(available);
});

socket
  .on('state', onInitState)
  .on('room:created', onRoomCreated)
  .on('room:updated', onRoomUpdated)
  .on('room:completed', onRoomUpdated)
  .on('room:started', onRoomStarted)
  .on('bet:created', onBetCreated)
  .on('bet:updated', onBetUpdated)
  .on('exception', onException)

function onInitState({ rooms: initRooms }: InitialState) {
  console.log('>> Rooms::init', initRooms);
  initRooms.forEach((dto) => {
    const index = rooms.findIndex((r) => r.id === dto.id);
    const room = toReactiveRoom(dto);

    index >= 0 ? rooms.splice(index, 1, room) : rooms.push(room);

    if (room.lastBet?.isWin === null) {
      room.isChecking = true;
    }
  });
}

function onRoomCreated(dto: RoomCreateDTO) {
  console.log('>> onRoomCreated', dto);
  const room = toReactiveRoom(dto);
  rooms.push(room);
  if (room.lastBet?.isWin === null) {
    room.isChecking = true;
  }
}

function onRoomUpdated(dto: RoomUpdateDTO) {
  console.log('>> onRoomUpdated', dto);
  const room = find(dto.id);
  if (room) {
    Object.assign(room, dto);
    if (room.hasJoined && room.isJoining) {
      room.isJoining = false;
    }
  }
}

function onRoomStarted(dto: RoomUpdateDTO) {
  console.log('>> onRoomStarted', dto);
  const room = find(dto.id);
  if (room) {
    if (room.hasJoined) {
      Object.assign(room, dto); 
    } else {
      rooms.splice(rooms.indexOf(room), 1);
    }
  }
}

function onBetCreated({ roomId, bet }: BetDTO) {
  console.log('>> onBetCreated', roomId, bet);
  const room = find(roomId);
  if (room) {
    // Should always be true
    if (!room.bets.find((b) => b.id === bet.id)) {
      room.bets.push(bet);
    }

    room.steps = [];
  }
}

function onBetUpdated({ roomId, bet }: BetDTO) {
  console.log('>> onBetUpdated', roomId, bet);
  const room = find(roomId);
  if (room) {
    room.bets = room.bets.slice().map((b) => (b.id === bet.id ? bet : b));

    if (bet.isWin !== null) {
      room.isChecking = false;
    }
  }
}

// Shitty solution
function onException() {
  rooms.forEach((r) => {
    r.isJoining = false;
    r.isChecking = false;
  });
}

function find(id: string): Room | undefined {
  return rooms.find((r) => r.id === id);
}

function findDemo(): Room | undefined {
  return rooms.find((r) => r.isDemo);
}

async function startDemo() {
  console.log('>> startDemo');
  try {
    await socket.timeout(2000).emitWithAck('startDemo');
  } catch (error) {
    shitHappened(error);
  }
}

function toReactiveRoom(roomDTO: RoomCreateDTO) {
  return reactive<Room>({
    isJoining: false,
    steps: [],
    bets: [],
    isChecking: false,
    ...roomDTO,
    get startIn() {
      return this.startAt ? differenceInSeconds(this.startAt, now.value) : 0;
    },
    get finishIn() {
      return this.finishAt ? differenceInSeconds(this.finishAt, now.value) : (this.isDemo ? Infinity : 0);
    },
    get isStarted() {
      return Boolean(this.startAt) && this.startIn <= 0;
    },
    get isFinished() {
      return Boolean(this.finishAt) && this.finishIn <= 0;
    },
    get inProgress() {
      return this.isStarted && !this.isFinished;
    },
    get startSoon() {
      return !!this.startAt && this.startIn < 60 * 5; // 5 minutes
    },
    get hasJoined() {
      return !!this.stats;
    },
    get gameStats() {
      return this.stats
        ? { ...this.stats, ...this.players.find(p => p.id === this.stats!.id) }
        : undefined;
    },
    get canJoin() {
      return (
        !this.isJoining &&
        !this.isStarted &&
        !this.hasJoined &&
        wallet.balance >= this.rules.deposit
      );
    },
    get canAddStep() {
      const enoughTime = (this.steps.length + 1) * this.rules.stepDurarion < this.finishIn - 1; // -1 for network latency
      return (
        this.inProgress &&
        !this.isChecking &&
        !!this.stats &&
        this.stats.betsAvailable > 0 &&
        this.steps.length < this.rules.winScores.length &&
        enoughTime
      );
    },
    get canPredict() {
      return this.inProgress && !this.isChecking && this.steps.length > 0;
    },
    get lastBet() {
      return this.bets[this.bets.length - 1];
    },
    get isBetChecking() {
      return this.isChecking && !!this.lastBet && this.lastBet.isWin === null;
    },
    get checkTimer() {
      const [lastStepStart] = this.lastBet?.steps.filter(s => s.startAt).map(s => s.startAt).slice(-1) || [];

      return this.isBetChecking && lastStepStart
        ? Math.max(0, this.lastBet!.stepDuration - differenceInSeconds(now.value, lastStepStart))
        : -1;
    },
    async join() {
      if (!this.canJoin) {
        return;
      }

      console.log('>>> Join room', this.id);
      this.isJoining = true;
      if (this.rules.deposit > 0) {
        try {
          await chargeWallet(this.address, this.rules.deposit, this.id);
        } catch (error) {
          shitHappened(error);
          this.isJoining = false;
        }
      } else {
        socket.emit('joinRoom', { roomId: this.id });
      }
    },
    addStep(value: boolean) {
      console.log('>>> Add step', value, this.steps);
      if (this.steps.length < this.rules.winScores.length) {
        this.steps.push(value);
      }
    },
    removeStep(index: number) {
      console.log('>>> Remove step', index, this.steps);
      this.steps.splice(index, 1);
    },
    async predict() {
      if (this.canPredict) {
        console.log('>>> predict', this.id, this.steps);
        this.isChecking = true;
        socket.emit('predict', { roomId: this.id, steps: this.steps });
      }
    },
  });
}

export {
  rooms,
  visibleRooms,
  joinedRooms,
  find,
  findDemo,
  startDemo
};
