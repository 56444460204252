<script lang="ts" setup>
import { computed } from 'vue';
import Fade from '~/components/transitions/Fade.vue';
import Labelled from '~/components/common/Labelled.vue';
import StyleValue from '~/components/common/StyleValue.vue';
import { type Room } from '~/state/Rooms';

const { room } = defineProps<{ room: Room }>();
const score = computed(() => room.gameStats?.score || 0);
</script>

<template>
  <div class="score">
    <Labelled below="Score">
      <Fade mode="out-in">
        <StyleValue :key="score" color="silver" class="score-value">
          {{ score }}
        </StyleValue>
      </Fade>
    </Labelled>
  </div>
</template>

<style lang="postcss" scoped>
.score {
  position: relative;
  &.hidden {
    visibility: hidden;
  }
}

.score-value {
  font-size: 2.4rem;
}
</style>
