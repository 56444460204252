<script lang="ts" setup>
import { computed } from 'vue';
import Labelled from '~/components/common/Labelled.vue';
import Money from '~/components/common/Money.vue';
import { type Room } from '~/state/Rooms';

const { room } = defineProps<{ room: Room }>();

const players = computed(() => room.players.slice().sort((a, b) => a.rank - b.rank));
const isPlayer = (id: string) => room.gameStats && room.gameStats.id === id;
const stoploss = computed(() => {
  const winners = room.players.filter(p => p.share > 0).length;
  return winners && winners < players.value.length ? winners - 1 : -1;
});
</script>

<template>
  <div class="ranking">
    <div v-if="room.isFinished" class="totals">
      <Labelled v-if="room.totalReward" above="Total rewards">
        <Money :value="room.totalReward" />
      </Labelled>
      <Labelled v-if="room.totalRefund" above="Total refunds">
        <Money :value="room.totalRefund" />
      </Labelled>
    </div>

    <div class="header row">
      <div class="name"></div>
      <div class="score">{{ $t('Score') }}</div>
      <div class="share">{{ $t('Reward') }}</div>
    </div>
    <ol class="list">
      <li v-for="(player, i) in players" :key="player.id">
        <div class="row" :class="{ highlight: isPlayer(player.id) }">
          <div class="place">{{ player.rank }}</div>
          <div class="name">{{ player.name }}</div>
          <div class="score">{{ player.score }}</div>
          <div class="share">{{ +(player.share * 100).toFixed(1) }}%</div>
        </div>

        <div v-if="stoploss === i" class="stoploss">
          <span class="before"></span>
          <div class="label">{{ $t('Stoploss') }}</div>
          <span class="after"></span>
        </div>
      </li>
    </ol>
  </div>
</template>

<style lang="postcss" scoped>
.ranking {
  overflow: auto;
  background: rgba(16, 17, 20, 0.9);
  box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(5px);
}

.totals {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  font-size: 1.5rem;
}

.header {
  font-weight: bold;
  font-size: 1.2em;
}

ol {
  margin: 0;
  padding: 0;
  font-size: 1.2em;

  li {
    display: flex;
    flex-direction: column;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  &.highlight {
    background: linear-gradient(90deg, rgba(142, 217, 255, 0.6), rgba(142, 217, 255, 0));
  }
}

.stoploss {
  position: relative;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;

  .before,
  .after {
    flex: 1;
    height: 1px;
    background: #c00000;
  }
  .label {
    padding: 4px 8px;
    color: #c00000;
    font-size: 0.8rem;
  }
}

.place {
  width: 30px;
  padding-right: 20px;
  text-align: right;
}
.name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.score {
  width: 60px;
  text-align: right;
  .header > & {
    width: 80px;
  }
}
.share {
  width: 100px;
  text-align: right;
}
</style>
