import { createChart, type UTCTimestamp, type IChartApi, type ISeriesApi, type LineData } from 'lightweight-charts';
import { effectScope, watchEffect } from 'vue';

import { CHART_CONFIG, SERIES_CONFIG, FAKELINE_CONFIG, staleColor } from './config';
import StartFinish from './plugins/StartFinish';
import Timeslots from './plugins/Timeslots';
import { type Room } from '~/state/Rooms';
import { allPrices, lastItem } from '~/state/Price';

export default class Chart {
  static MAX_TIMESLOTS = 7;

  private chart: IChartApi;
  private series: ISeriesApi<'Area'>;
  private predictionLine: ISeriesApi<'Line'>;
  private startFinish: StartFinish<'Line'>;
  private timeslots?: Timeslots<'Line'>;

  private effects = effectScope();

  constructor(root: HTMLElement, private room: Room) {
    this.chart = createChart(root, CHART_CONFIG);
    this.series = this.chart.addAreaSeries(SERIES_CONFIG);
    this.predictionLine = this.chart.addLineSeries(FAKELINE_CONFIG);
    this.startFinish = new StartFinish(this.predictionLine, room);
    this.series.setData(allPrices.value);

    this.timeslots = new Timeslots(this.predictionLine, this.chart.timeScale(), room);

    this.effects.run(() => {
      watchEffect(() => {
        const price = lastItem.value;
        if (price) {
          this.series.update(price.stalled ? { ...price, ...staleColor } : price);
          this.setFakeData(price);
        }
      });

      watchEffect(() => {
        room.startIn; // trigger dependency
        const timeScale = this.chart.timeScale();
        if (room.isFinished || room.isChecking && this.position > this.minPosition) {
          return;
        }

        timeScale.scrollToPosition(this.position, Boolean(timeScale.scrollPosition()));
      });
    });
  }

  destroy() {
    this.effects.stop();
    this.startFinish.destroy();
    this.timeslots?.destroy();
    this.chart.remove();
  }

  get predictionLineLength() {
    return Math.round((Chart.MAX_TIMESLOTS + 1.5) * this.room.rules.stepDurarion);
  }

  get minPosition() {
    return -Math.round(this.predictionLineLength / 2);
  }

  get position() {
    const room = this.room;
    const visibleSlots = room.steps.length || room.lastBet?.steps.length || 0;

    return Math.max(
      this.minPosition,
      Math.min(-1, (visibleSlots - Chart.MAX_TIMESLOTS) * room.rules.stepDurarion - this.totalCheckTime)
    );
  }

  get totalCheckTime() {
    const { lastBet, rules } = this.room;

    return this.room.isChecking && lastBet
      ? (lastBet.steps.filter(s => s.isChecked).length + 1) * rules.stepDurarion - this.room.checkTimer
      : 0;
  }

  private setFakeData(lastData: LineData) {
    const fakeData = Array.from({ length: this.predictionLineLength }).map(
      (_, i) => ({ time: Number(lastData.time) + i as UTCTimestamp, value: lastData.value })
    );
    this.predictionLine.setData(fakeData);
  }
}
