import { reactive, computed } from 'vue';

import { InitialState } from './index';
import { socket } from './Connection';
import { Room } from './Rooms';

export type ProgressRecord = {
  deposit: number;
  risk: number;
  completed: number;
};

const records = reactive<ProgressRecord[]>([]);
const totalCompleted = computed(() => records.reduce((acc, record) => acc + record.completed, 0));

socket
  .on('state', onStateInit)
  .on('game:progress', onGameProgress);

function onStateInit(state: InitialState) {
  console.log('>> Progress::init', state.progress);
  records.push(...state.progress);
}

function onGameProgress(progress: ProgressRecord[]) {
  console.log('>> Progress::progress', progress);
  progress.forEach((record) => {
    const existing = progress.find((item) => item.deposit === record.deposit && item.risk === record.risk);
    if (existing) {
      Object.assign(existing, record);
    } else {
      records.push(record);
    }
  });
}

function getByRoom(room: Room) {
  const { deposit, risk } = room.rules;
  return records.find((record) => record.deposit === deposit && record.risk === risk);
}



export {
  records,
  totalCompleted,
  getByRoom,
};