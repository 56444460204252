<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{ seconds: number; alt?: string }>();
const { t } = useI18n();

const time = computed(() => {
  const seconds = Math.max(0, props.seconds);
  return seconds === 0 && props.alt
    ? t(props.alt)
    : Math.floor(seconds / 60).toString().padStart(2, '0')
      + ':'
      + (seconds % 60).toString().padStart(2, '0');
});
</script>

<template>
  <span class="timer">{{ time }}</span>
</template>
