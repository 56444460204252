<script setup lang="ts">
import { useProjection } from '@vueuse/math';

const props = defineProps<{ risk: number }>();
const h = useProjection(props.risk, [0, 1], [120, 0]);
</script>

<template>
  <svg viewBox="0 0 100 80">
    <path
      fill="url(#risk-level-1)"
      d="M15.407 15.64c-7.531 7.708-12.3 17.934-12.786 29.221-.022.518.156.972.531 1.347.374.374.84.565 1.38.565h9.571c1.018 0 1.856-.764 1.91-1.739.408-7.55 3.543-14.728 8.864-20.302l-9.47-9.092Z"
    />
    <path
      fill="url(#risk-level-2)"
      d="M48.47 1.275c-11.94.364-22.765 4.964-30.9 12.288l9.47 9.091c5.878-5.171 13.466-8.188 21.43-8.521V1.275Z"
    />
    <path
      fill="url(#risk-level-3)"
      d="m75.123 24.731 9.47-9.091c7.531 7.709 12.3 17.934 12.786 29.221.022.518-.156.972-.531 1.347-.176.181-.39.326-.628.423a1.918 1.918 0 0 1-.752.142h-9.571c-.49 0-.96-.18-1.316-.504a1.804 1.804 0 0 1-.594-1.235c-.408-7.55-3.542-14.73-8.864-20.303Z"
    />
    <path
      fill="url(#risk-level-4)"
      d="M51.53 14.133c8.245.35 15.728 3.516 21.43 8.521l9.47-9.091C74.295 6.239 63.47 1.639 51.53 1.275v12.858Z"
    />
    <path
      :fill="`hsl(${h}deg 100% 38%)`"
      d="M50 31.332a8.739 8.739 0 0 0-4.266 1.089 8.3 8.3 0 0 0-3.115 3.003l-18.66 31.03a7.877 7.877 0 0 0-1.152 4.09c0 1.437.398 2.849 1.151 4.09a8.3 8.3 0 0 0 3.116 3.003 8.738 8.738 0 0 0 4.266 1.088h37.32a8.74 8.74 0 0 0 4.266-1.088 8.301 8.301 0 0 0 3.116-3.002 7.877 7.877 0 0 0 1.15-4.091 7.877 7.877 0 0 0-1.15-4.09l-18.66-31.03a8.303 8.303 0 0 0-3.116-3.003A8.74 8.74 0 0 0 50 31.332Zm-.566 11.799h1.13c.3-.004.598.052.874.164.277.113.525.28.73.49.213.205.378.45.486.719.107.27.155.557.14.846l-.564 14.992a2.104 2.104 0 0 1-.681 1.464c-.416.385-.971.6-1.549.6a2.283 2.283 0 0 1-1.55-.6 2.106 2.106 0 0 1-.682-1.464l-.563-14.992a1.993 1.993 0 0 1 .14-.846c.107-.27.272-.514.484-.719a2.201 2.201 0 0 1 1.605-.654ZM50 67.305c.758 0 1.485.289 2.02.804a2.69 2.69 0 0 1 .838 1.94 2.69 2.69 0 0 1-.837 1.94 2.92 2.92 0 0 1-2.02.804c-.376 0-.748-.07-1.095-.209a2.867 2.867 0 0 1-.928-.595 2.739 2.739 0 0 1-.62-.89 2.65 2.65 0 0 1 0-2.1 2.74 2.74 0 0 1 .62-.89 2.87 2.87 0 0 1 .928-.596A2.962 2.962 0 0 1 50 67.305Z"
    />
    <defs>
      <linearGradient
        id="risk-level-1"
        x1="3.125"
        x2="18.805"
        y1="47"
        y2="19.438"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00C000" />
        <stop offset="1" stop-color="#60C000" />
      </linearGradient>
      <linearGradient
        id="risk-level-2"
        x1="22.396"
        x2="48.109"
        y1="18"
        y2="6.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#60C000" />
        <stop offset="1" stop-color="#C0C000" />
      </linearGradient>
      <linearGradient
        id="risk-level-3"
        x1="79.688"
        x2="95.882"
        y1="20.5"
        y2="47.593"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C06000" />
        <stop offset="1" stop-color="#C00000" />
      </linearGradient>
      <linearGradient
        id="risk-level-4"
        x1="51.563"
        x2="77.276"
        y1="7.5"
        y2="18.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C0C000" />
        <stop offset="1" stop-color="#C06000" />
      </linearGradient>
    </defs>
  </svg>
</template>
