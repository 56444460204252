import { ref, watch } from 'vue';
export { default as Toast } from './Toast.vue';
import { globalError } from '~/state';
import router from '~/router';

export type ToastType = 'info' | 'success' | 'warning' | 'error';
export type ToastOptions = {
  type: ToastType;
  duration: number;
  dismissable: boolean;
  onClose?: () => void;
};

const toastData = ref<ToastOptions & { message: string, hide: () => void }>();
const defaultOptions: ToastOptions = {
  type: 'info',
  duration: 5000,
  dismissable: true,
};

function hide() {
  toastData.value?.onClose?.();
  toastData.value = undefined;
}

function showToast(message: string, options?: Partial<ToastOptions>) {
  const toast = { message, ...defaultOptions, ...options, hide };

  toastData.value = toast;
  if (toast.duration > 0) {
    setTimeout(hide, toast.duration);
  }
}

function getToast() {
  return toastData;
}

watch(globalError, (error) => {
  if (error) {
    showToast(error, {
      type: 'error',
      onClose: () => globalError.value = undefined,
    });
  }
});

router.afterEach(hide);

export {
  showToast,
  getToast
};
