import { CanvasRenderingTarget2D } from 'fancy-canvas';
import {
  IChartApi,
  ISeriesPrimitive,
  ISeriesPrimitivePaneRenderer,
  ISeriesPrimitivePaneView,
  SeriesAttachedParameter,
  Time,
  UTCTimestamp,
  Point,
  Coordinate,
} from 'lightweight-charts';

export default class ScoreValue implements ISeriesPrimitive {
  private chart!: IChartApi;
  private _paneViews!: PaneView[];

  constructor(public getTime: () => UTCTimestamp, public value: number) {
    this._paneViews = [new PaneView(this)];
  }

  getXCoodinate() {
    const timeScale = this.chart.timeScale();
    return timeScale.timeToCoordinate(this.getTime());
  }

  getYCoodinate() {
    return this.chart.paneSize().height / 2 as Coordinate;
  }

  attached(param: SeriesAttachedParameter<Time, 'Line'>): void {
    this.chart = param.chart;
  }

  updateAllViews() {
    this._paneViews.forEach(view => view.update());
  }

  paneViews() {
    return this._paneViews;
  }
}

class PaneView implements ISeriesPrimitivePaneView {
  private position: Point | null = null;

  constructor(private source: ScoreValue) {  }

  update() {
    const x = this.source.getXCoodinate();
    const y = this.source.getYCoodinate();

    if (x === null || y === null) {
      return;
    }

    this.position = { x, y };
  }

  renderer() {
    return new PaneViewRenderer(this.source.value, this.position);
  }
}

class PaneViewRenderer implements ISeriesPrimitivePaneRenderer {
  constructor(private value: number, private pos: Point | null) { }

  draw(target: CanvasRenderingTarget2D) {
    target.useBitmapCoordinateSpace(({ context, horizontalPixelRatio }) => {
      if (this.pos === null) {
        return;
      }

      const dpr = horizontalPixelRatio;
      const size = 20 * dpr;
      const x = this.pos.x * dpr;
      const y = this.pos.y * dpr;

      context.save();

      context.font = `${size}px SFPro, sans-serif`;
      context.textAlign = 'left';
      context.fillStyle = '#ffffff';
      context.fillText(String(this.value), x, y + size / 3);

      context.restore();
    });
  }
}
