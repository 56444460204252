<script lang="ts" setup>
import { computed } from 'vue';
import Labelled from '~/components/common/Labelled.vue';
import StyleValue from '~/components/common/StyleValue.vue';
import Timer from '~/components/common/Timer.vue';
import { type Room } from '~/state/Rooms';

const { room } = defineProps<{ room: Room }>();

const label = computed(() => {
  if (room.startIn > 0) {
    return 'Start in';
  } else if (room.finishIn > 0) {
    return 'Finish in';
  }
  return '';
});
</script>

<template>
  <Labelled :above="label" class="game-timer">
    <StyleValue v-if="room.isFinished" color="gold">
      {{ $t('Game over') }}
    </StyleValue>
    <StyleValue v-else-if="room.inProgress" color="gold">
      <Timer :seconds="room.finishIn" />
    </StyleValue>
    <StyleValue v-else-if="!room.isStarted" color="silver">
      <Timer  :seconds="room.startIn" />
    </StyleValue>
  </Labelled>
</template>

<style lang="postcss" scoped>
.game-timer :deep(.content) {
  font-size: 1.8rem;
}
</style>
