import { computed } from 'vue';
import { until } from '@vueuse/core';

import router from '~/router';
import { Room } from '~/state/Rooms';
import { identity, finishOnboard } from '~/state/Account';
import { completeTask } from '~/state/Tasks';
import { StepButton, popperCenter, Step } from './index';

export const demoCompleted = computed(() => identity.onboarding.demo);

const okButton: StepButton = {
  label: 'Ok',
  cssClass: 'primary',
  fn: ({ next }) => next()
};

export const introSteps: Step[] = [{
  ...popperCenter,
  content: {
    title: 'Welcome seer',
    description: 'DemoIntro',
    buttons: [
      {
        id: 'skip-demo',
        label: 'Skip',
        fn: ({ exit }) => {
          exit();
          finishOnboard('demo');
        }
      },
      {
        id: 'start-demo',
        label: 'Start demo',
        cssClass: 'primary',
        fn: async ({ next }) => {
          await router.push({ name: 'demo' });
          setTimeout(next, 100);
        }
      }
    ]
  },
}];

export const getDemoSteps = (room: Room) => {
  const steps: Step[] = [
    {
      ...popperCenter,
      content: {
        title: 'Demo game',
        description: 'DemoStep1',
        buttons: [okButton]
      },
    },
    {
      attachTo: {
        element: '.controls',
      },
      content: {
        title: '',
        description: 'DemoStep2'
      },
      on: {
        beforeStep({ next }) {
          until(() => room.bets.length === 0 && room.steps.length > 0).toBe(true).then(next);
        }
      }
    },
    {
      attachTo: {
        element: '.controls .ball',
      },
      content: {
        title: '',
        description: 'DemoStep3'
      },
      on: {
        beforeStep({ next }) {
          until(() => room.bets.length === 0 && room.isChecking).toBe(true).then(next);
        }
      },
      options: {
        popper: {
          placement: 'top',
        }
      }
    },
    {
      attachTo: {
        element: '.chart',
      },
      content: {
        title: '',
        description: 'DemoStep4',
      },
      on: {
        async beforeStep({ next }) {
          setTimeout(next, 5000);
        }
      }
    },
    {
      ...popperCenter,
      content: {
        title: '',
        description: () => room.lastBet?.isWin ? 'DemoStep5_1' : 'DemoStep5_2',
        buttons: [okButton]
      },
      on: {
        async beforeStep() {
          await until(() => room.bets.length === 1 && !room.isBetChecking).toBe(true);
        }
      }
    },
    {
      ...popperCenter,
      content: {
        title: '',
        description: 'DemoStep6',
        buttons: [okButton]
      },
      on: {
        beforeStep({ next }) {
          until(() => room.steps.length > 0).toBe(true).then(next);
        }
      }
    },
    {
      attachTo: {
        element: '.score',
      },
      content: {
        title: '',
        description: 'DemoStep7',
        buttons: [okButton]
      },
      on: {
        async beforeStep() {
          await until(() => room.bets.length === 2 && !room.isBetChecking).toBe(true);
          finishOnboard('demo');
        }
      }
    },
    {
      ...popperCenter,
      content: {
        title: '',
        description: 'DemoStep8',
        buttons: [okButton]
      },
    },
    {
      ...popperCenter,
      content: {
        title: '',
        description: 'DemoStep9',
        buttons: [okButton]
      },
      on: {
        async beforeStep() {
          await until(() => room.gameStats!.score >= 3).toBe(true);
        }
      }
    },
    {
      ...popperCenter,
      content: {
        title: '',
        description: 'DemoStep10',
        buttons: [{
          label: 'Go back',
          cssClass: 'primary',
          fn: ({ next }) => {
            next();
            completeTask('demo');
            router.back();
          }
        }]
      }
    },
  ];

  if (demoCompleted.value) {
    return [];
  } else if (room.bets.length === 0) {
    return steps;
  } else if (room.bets.length === 1) {
    return steps.slice(5);
  } else if (room.gameStats!.score < 3) {
    return steps.slice(7);
  }

  finishOnboard('demo');
  return [];
};