<script setup lang="ts">
import { ref } from 'vue';
import Fade from '~/components/transitions/Fade.vue';
import ProgressArrow from './ProgressArrow.vue';

const props = defineProps<{ size: { width: number; height: number } }>();
const arrows = ['left', 'right', 'up', 'down'] as const;
const hintVisible = ref(false);

const getArrowPosition = (direction: typeof arrows[number]) => {
  const { width, height } = props.size;
  if (direction === 'left' || direction === 'right') {
    return {
      left: direction === 'left' ? '-50px' : undefined,
      right: direction === 'right' ? '-50px' : undefined,
      top: height / 2 + 'px',
      transform: 'translateY(-50%)',
    };
  } else {
    return {
      top: direction === 'up' ? '-60px' : undefined,
      bottom: direction === 'down' ? '-60px' : undefined,
      left: width / 2 + 'px',
      transform: 'translateX(-50%)',
    };
  }
};
</script>

<template>
  <div class="progress-hint">
    <Fade>
      <div v-if="hintVisible" class="hint">
        Complete cards to progress and find your sweet spot
      </div>
    </Fade>

    <ProgressArrow
      v-for="arrow in arrows"
      :key="arrow"
      :direction="arrow"
      class="arrow"
      :style="getArrowPosition(arrow)"
      @click="hintVisible = true"
    />
  </div>
</template>

<style lang="postcss" scoped>
.progress-hint .hint {
  z-index: 1;
  position: absolute;
  top: 50px;
  left: -70px;
  width: 240px;
  transform: rotate(-15deg);
  font: 1.5rem Virgil;
  text-align: center;
}
.progress-hint .arrow {
  z-index: 1;
  position: absolute;
  cursor: pointer;
}
</style>
