<script lang="ts" setup>
import { computed } from 'vue';
import TonIcon from '~/assets/icons/TonIcon.vue';

const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
  precision: {
    type: Number,
    default: 2,
  },
  white: {
    type: Boolean,
  },
  icon: {
    type: String,
  },
});

const money = computed(() => {
  return (
    Math.floor(props.value * 10 ** props.precision) /
    10 ** props.precision
  ).toString();
});
</script>

<template>
  <div class="money">
    <span v-if="icon" class="icon">{{ icon }}</span>
    <TonIcon v-else :filled="!white" class="icon" />
    <span class="value">{{ money }}</span>
  </div>
</template>

<style lang="postcss" scoped>
.money {
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
}

.icon {
  width: 1.2em;
  height: 1.2em;
}

.value {
  line-height: 1;
}
</style>
