<script lang="ts" setup>
import { ref } from 'vue';
import BottomSheet from '~/components/special/BottomSheet.vue';
import Button from '~/components/common/Button.vue';
import HelpIcon from '~/assets/icons/help.svg';

const open = ref(false);
</script>

<template>
  <Button class="help-btn" highlight @click="open = true">
    <HelpIcon class="icon" />
  </Button>

  <BottomSheet :open="open" @close="open = false">
    <HelpIcon class="lottie" />
    <dl class="help-list">
      <dt>⚔️</dt>
      <dd>{{ $t('GameHelpLine1') }}</dd>

      <dt>🎖️</dt>
      <dd>{{ $t('GameHelpLine2') }}</dd>

      <dt>🎱</dt>
      <dd>{{ $t('GameHelpLine3') }}</dd>

      <dt>🏆</dt>
      <dd>{{ $t('GameHelpLine4') }}</dd>
    </dl>
  </BottomSheet>
</template>

<style lang="postcss" scoped>
.icon {
  width: 20px;
  height: 20px;
}

.lottie {
  display: block;
  width: 80px;
  height: 80px;
  margin: 20px auto;
  filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.9));
}

.help-list {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 10px;

  > dd {
    margin: 0;
  }
}
</style>
