<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { loadEngine } from './index';
import { PresetName, presets } from './presets';

const { preset } = defineProps<{ preset: PresetName }>();

const id = Math.random().toString(36).substring(7);
const engine = await loadEngine();
const el = ref();
let container: Awaited<ReturnType<typeof engine.load>>;

onMounted(async () => {
  container = await engine.load({
    element: el.value,
    options: presets[preset],
  });
});

onUnmounted(() => {
  container?.destroy();
});
</script>

<template>
  <Teleport to="body">
    <div v-if="preset" :key="preset" ref="el" :id="id" class="particles"></div>
  </Teleport>
</template>

<style lang="postcss" scoped>
.particles {
  z-index: -1;
}
</style>
