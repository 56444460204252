<script lang="ts" setup>
import { ref, computed, watch, watchEffect } from 'vue';
import { BackButton } from 'vue-tg';
import { Background } from '~/components/special/background';
import { Toast } from '~/components/special/toast';
import Onboard from '~/components/special/onboard/Onboard.vue';
import { getDemoSteps } from '~/components/special/onboard/demo';
import SlideIn from '~/components/transitions/SlideIn.vue';
import DemoStats from '~/components/game/DemoStats.vue';
import Chart from '~/components/game/chart/Chart.vue';
import Controls from '~/components/game/Controls.vue';
import { startDemo, findDemo } from '~/state/Rooms';

await startDemo();
const room = computed(() => findDemo());
const steps = ref<ReturnType<typeof getDemoSteps>>();
const showBack = ref(false);
let winScores: number[] = [];

const stopWatch = watch(
  room,
  (room) => {
    if (room) {
      steps.value = getDemoSteps(room);
      winScores = room.rules.winScores;
      stopWatch();
    }
  },
  { immediate: true }
);

watchEffect(() => {
  if (room.value) {
    const { rules, bets } = room.value;
    rules.winScores = bets.length === 0 ? winScores.slice(0, 1) : winScores;
    showBack.value = room.value.bets.length > 1;
  }
});
</script>

<template>
  <div v-if="room" class="game-room">
    <BackButton v-if="showBack" @click="$router.back" />
    <Background preset="space" />
    <Onboard v-if="steps" :steps="steps" />

    <DemoStats class="game-stats" :room="room" />

    <div class="workzone">
      <Toast />

      <Chart :room="room" class="chart" />

      <div class="controls-container">
        <SlideIn to="top">
          <Controls :room="room" />
        </SlideIn>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.game-room {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.game-stats {
  flex-shrink: 0;
}

.workzone {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  & > :deep(.ranking) {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 75%;
  }
}

.chart {
  flex: 1;
}

.controls-container {
  height: 25%;
  display: flex;
  justify-content: center;
}
</style>
