<script lang="ts" setup>
import { computed } from 'vue';
import Money from '~/components/common/Money.vue';
import Labelled from '~/components/common/Labelled.vue';
import StyleValue from '~/components/common/StyleValue.vue';
import Fade from '~/components/transitions/Fade.vue';
import { type Room } from '~/state/Rooms';

const { room } = defineProps<{ room: Room }>();
const bets = computed(() => room.gameStats?.betsAvailable || 0);
const refund = computed(() => room.gameStats?.refund || 0);
</script>

<template>
  <div class="balance">
    <Fade :duration="500">
      <Labelled v-if="room.isFinished" above="Refund" class="refund">
        <Money :value="refund" />
      </Labelled>
      <Labelled v-else above="Trades" class="trades">
        <StyleValue>{{ bets }}</StyleValue>
      </Labelled>
    </Fade>
  </div>
</template>

<style lang="postcss" scoped>
.refund {
  font-size: 1.5rem;
}
.trades {
  font-size: 2.1rem;
}
</style>
