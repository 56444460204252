<script lang="ts" setup>
import { computed } from 'vue';
import Labelled from '~/components/common/Labelled.vue';
import Button from '~/components/common/Button.vue';
import TrophyIcon from '~/assets/icons/trophy.vue';
import { type Room } from '~/state/Rooms';

const { room } = defineProps<{ room: Room }>();
defineEmits<{ click: [boolean] }>();

const leader = computed(() => room.gameStats && room.gameStats.rank === 1 && room.gameStats.score > 0);
const place = computed(() => room.gameStats && room.gameStats.rank <= 5 ? room.gameStats.rank : '');
</script>

<template>
  <div class="ranking-button">
    <Labelled above="Ranking">
      <Button toggle class="button" @click="$emit('click', $event)">
        <TrophyIcon :golded="leader" class="icon" />
        <span v-if="true" class="place" :class="{ leader }">
          {{ place }}
        </span>
      </Button>
    </Labelled>
  </div>
</template>

<style lang="postcss" scoped>
.ranking-button {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.button {
  padding: 0 12px 6px;
  filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.9));

  &.pressed::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 45%;
    left: 50%;
    box-shadow: 0 0 20px 15px #f9c84b;
  }
}

.icon {
  width: 30px;
  height: 30px;
}

.place {
  position: absolute;
  top: 7%;
  left: 0.5px;
  width: 100%;
  font-size: 0.6rem;
  line-height: 1rem;
  text-align: center;
  color: #0f0701;

  &.leader {
    color: #ffffff;
  }
}
</style>
