<script lang="ts" setup>
import { useRouter } from 'vue-router';
import OnlineCounter from '~/components/market/OnlineCounter.vue';
import RoomGrid from '~/components/market/RoomGrid.vue';
import { visibleRooms } from '~/state/Rooms';

const router = useRouter();

const onRoomSelect = (roomId: string) => {
  router.push({ name: 'rules', params: { roomId } });
};
</script>

<template>
  <div class="market">
    <OnlineCounter class="online" />

    <div class="rooms-view">
      <RoomGrid :rooms="visibleRooms" @select="onRoomSelect" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.market {
  position: relative;
  display: flex;
  align-items: start;
}

.online {
  position: absolute;
  top: 4px;
  left: 8px;
}

.rooms-view {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>
