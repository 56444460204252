<script lang="ts" setup>
import { ref, watch, computed } from 'vue';
import { until } from '@vueuse/core';
import { useWebAppHapticFeedback } from 'vue-tg';
import { useI18n } from 'vue-i18n';
import Fade from '~/components/transitions/Fade.vue';
import SlideIn from '~/components/transitions/SlideIn.vue';
import Button from '~/components/common/Button.vue';
import { type Room } from '~/state/Rooms';
import UpIcon from '~/assets/icons/up.svg';
import DownIcon from '~/assets/icons/down.svg';
import EclipseSpinner from '~/assets/icons/eclipse-half.svg';

const { room } = defineProps<{ room: Room }>();
const { t } = useI18n();
const { impactOccurred, notificationOccurred } = useWebAppHapticFeedback();
const isWin = ref<boolean | null>(null);

const ballContent = computed(() => {
  if (!room.isStarted) {
    return t('Wait');
  } else if (room.canPredict) {
    return t('Go');
  } else if (room.bets.length === 0 && room.steps.length === 0) {
    return t('Plan');
  } else if (room.isChecking) {
    return room.checkTimer >= 0 ? String(room.checkTimer) : '';
  } else if (isWin.value === true) {
    return t('Win');
  } else if (isWin.value === false) {
    return t('Loss');
  } else {
    return '';
  }
});

const ballClasses = computed(() => ({
  active: room.canPredict,
  pressed: room.isChecking || !room.canAddStep,
  win: isWin.value === true,
  lose: isWin.value === false,
}));

const ballFont = computed(() => {
  const ballWidth = 100;
  const size = ballWidth / Math.max(ballContent.value.length, 1);
  return Math.min(size, 21) + 'px';
});

const onBallTap = async () => {
  impactOccurred('soft');
  await room.predict();
  await until(() => room.isChecking).toBe(false);
  notificationOccurred(room.lastBet?.isWin ? 'success' : 'error');
}

watch(
  () => room.lastBet?.isWin,
  (win) => {
    if (typeof win === 'boolean') {
      isWin.value = win;
      setTimeout(() => (isWin.value = null), 1500);
    }
  }
);
</script>

<template>
  <div class="controls">
    <div class="arrow up">
      <SlideIn to="left">
        <div v-if="room.canAddStep" class="button-container">
          <Button class="arrow-button" @click="room.addStep(true)">
            <UpIcon class="icon" />
          </Button>
        </div>
      </SlideIn>
    </div>

    <Button
      class="ball"
      :disabled="!room.canPredict"
      :class="ballClasses"
      :style="{ 'font-size': ballFont }"
      @click="onBallTap"
    >
      <Fade>
        <span :key="ballContent" class="content">{{ ballContent }}</span>
      </Fade>
      <EclipseSpinner v-if="room.isChecking" class="spinner" />
    </Button>

    <div class="arrow down">
      <SlideIn to="right">
        <div v-if="room.canAddStep" class="button-container">
          <Button class="arrow-button" @click="room.addStep(false)">
            <DownIcon class="icon" />
          </Button>
        </div>
      </SlideIn>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.controls {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ball {
  z-index: 2;
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #202228;

  /* font-size: clamp(0.8rem, 3.5vw, 1.4rem); */
  text-transform: uppercase;
  filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.5));
  transition: background-color 0.8s ease;

  &:disabled,
  &.pressed {
    filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.7));
  }

  &.win {
    background-color: #349e21;
  }
  &.lose {
    background-color: #aa1a1a;
  }
  &.active {
    background-color: #027dff;
  }

  > .content {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    padding-left: 2px;
    text-align: center;
    line-height: 0;
  }

  > .spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    fill: #fff;
  }
}

.arrow {
  z-index: 1;
  overflow: hidden;
  position: relative;
  width: 140px;
  height: 90px;
  &.up {
    left: 35px;
    padding: 10px 0 10px 20px;
  }
  &.down {
    left: -35px;
    padding: 10px 20px 10px 0;
  }
}

.button-container,
.arrow-button {
  width: 100%;
  height: 100%;
}

.arrow-button {
  display: flex;
  align-items: center;
  padding: 0 28px;
  background: #16181e;
  filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.9));

  &:active {
    filter: none;
  }

  .up & {
    justify-content: flex-start;
    border-radius: 35px 0 0 35px;
  }
  .down & {
    justify-content: flex-end;
    border-radius: 0 35px 35px 0;
  }

  > .icon {
    height: 50%;
    filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.9));
  }
}
</style>
